<div class="InfoBanco">

  <app-page-title>Qual a sua conta para depósito?</app-page-title>

  <ul class="InfoBanco-warning">
    <li><strong>Lembrando que:</strong></li>
    <li>• <strong>Conta salário</strong> não recebe transferências (TED, DOC, Depósitos);</li>
    <li>• <strong>Conta caixa fácil</strong> (operação 023) possui um limite de saldo e movimentação máxima mensal;</li>
    <li>• A <strong>conta</strong> que irá receber o depósito deve, obrigatoriamente, ser <strong>nominal ao </strong>próprio
      <strong>cliente</strong>.
    </li>
  </ul>

  <form [formGroup]="mainForm" (submit)="nextPage();" novalidate>

    <mat-form-field class="fullWidth">

      <mat-select class="bigFont" placeholder="Tipo de conta bancária" formControlName="tipoContaPagto">
        <mat-option *ngFor="let opt of howReceivePayment" [value]="opt.value">
          {{opt.text}}
        </mat-option>
      </mat-select>

    </mat-form-field>

    <mat-form-field class="fullWidth">

      <input type="text" placeholder="Bancos" aria-label="Number" matInput formControlName="bancoPagtoName" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let opt of bankInfoDataFiltered | async" [value]="opt.nome" >
          {{opt.nome}}
        </mat-option>
      </mat-autocomplete>

    </mat-form-field>

    <div class="InfoBanco-numberWithDv">
      <mat-form-field class="agency " [hideRequiredMarker]="true">
        <input
          class="bigFont"
          type="tel"
          matInput
          placeholder="Agência"
          name="agency"
          formControlName="agenciaPagto"
          maxlength="5"
        >
      </mat-form-field>

      <mat-form-field class=" smallInput " [hideRequiredMarker]="true">
        <input
          class="bigFont "
          type="tel"
          matInput
          placeholder="Dígito"
          name="agencyDv"
          formControlName="digitoAgenciaPagto"
          maxlength="2"
        >
      </mat-form-field>
    </div>

    <div class="InfoBanco-numberWithDv">
      <mat-form-field class="account " [hideRequiredMarker]="true">
        <input
          class="bigFont"
          type="tel"
          matInput
          placeholder="Conta"
          name="account"
          formControlName="contaPagto"
          maxlength="12"
        >
      </mat-form-field>

      <mat-form-field class=" smallInput " [hideRequiredMarker]="true">
        <input
          class="bigFont"
          type="tel"
          matInput
          placeholder="Dígito"
          name="accountDv"
          formControlName="digitoContaPagto"
          maxlength="2"
        >
      </mat-form-field>

    </div>

    <div class="flex-end">
      <button mat-raised-button color="accent">Continuar</button>
    </div>

  </form>

</div>
