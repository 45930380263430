import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringUtilsService {

  constructor() { }

  /**
   * Formata string para dd/mm/aaaa
   * @param str Uma string com dd<any>mm<any>aaaa
   */
  public formatToDateBr(str: string) : string {
    const strTmp = str.replace(/[\\/.,\- ]/g, '');
    return strTmp.slice(4,8) + '-' + strTmp.slice(2, 4) + '-' + strTmp.slice(0,2);
  }

  public convertDateBrToUs(str: string): string {
    if(str.search('-') != -1) {
     return str.split(' ')[0];
    }

    const strTmp = str.split('.');
    return `${strTmp[2]}-${strTmp[1]}-${strTmp[0]}`;
  }

  public formatAndConvertUsToBr(str:string): string {
    const tmp = this.formatToDateUs(str);
    return this.convertDateUsToBr(tmp);
  }

  /**
   * Formata string para aaaa-mm-dd
   * @param str Uma string com aaaa<any>mm<any>dd
   */
  public formatToDateUs(str: string) : string {
    const strTmp = str.replace(/[\\/.,\- ]/g, '');
    return strTmp.slice(0,4) + '-' + strTmp.slice(4, 6) + '-' + strTmp.slice(6,8);
  }

  public convertDateUsToBr(str: string): string {
    const strTmp = str.split('-');
    return `${strTmp[2]}/${strTmp[1]}/${strTmp[0]}`;
  }

  public removeTelMask(str: string) : string {
    return str.replace(/[()/ -]/g, '');
  }

  public removeCpfMask(str: string) : string {
    return str.replace(/[.\- ]/g, '');
  }

  public static moneyToNumber(str: string) : number {
    let mon = str.replace(/[R$ .]/g, '');
    mon = mon.replace(/,/g, '.');

    return parseFloat(mon);
  }

  public removeCepMask(cep: string) {
    return cep.replace(/[ .\-]/g, '');
  }

  public removeDateMask(date: string) {
    return date.replace(/[ /\-.\\]/g, '');
  }

  public removeRgMask(rg: string) {
    return rg.replace(/[.\-]/g, '');
  }

  convertDatePadraoToUs(date: string) {
    return `${date.substr(0,4)}-${date.substr(4,2)}-${date.substr(6,2)}`;
  }
}
