<div class="Questionnaire">

  <app-page-title>Questionário
<!--    <button mat-icon-button *ngIf="showReloadButton" class="Questionnaire-button&#45;&#45;refreshButton" (click)="reloadQuestions()">-->
<!--      <i class="icofont-refresh"></i>-->
<!--    </button>-->
  </app-page-title>

  <form [formGroup]="form" (submit)="sendForm()">
    <div class="Questionnaire-content">

      <div class="Questionnaire-question" *ngFor="let question of questions; index as i">
        <label class="Questionnaire-questionTitle">{{question.question}}</label>
        <mat-radio-group formControlName="_{{i}}">
          <mat-radio-button class="Questionnaire-questionAnswer" *ngFor="let answer of question.answers" [value]="answer">
            {{answer | titlecase}}
          </mat-radio-button>
        </mat-radio-group>
      </div>

    </div>

    <div class="flex-end">
      <button type="submit" mat-raised-button color="accent">Continuar</button>
    </div>

  </form>

</div>
