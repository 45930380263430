import {Component, OnInit} from '@angular/core';
import {ErrorUtilsService} from '../../../services/utils/error/error-utils.service';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import {MyHttpUtils} from '../../../services/utils/HttpUtils';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EPageLoaderStatus} from '../../../services/my-globals/definitions';
import {catchError} from 'rxjs/operators';
import {IServerError} from '../../../services/utils/error/definitions';
import {IHttpResponseProposalsList, IProposalsListProp, IProposalsListPropLinks, IProposalsListPropSimple} from './definitions';
import {StringUtilsService} from '../../../services/utils/string-utils.service';
import {UserTermsDialogComponent} from '../../../components/dialogs/user-terms-dialog/user-terms-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'app-lista-pedidos',
  templateUrl: './lista-pedidos.component.html',
  styleUrls: ['./lista-pedidos.component.scss']
})
export class ListaPedidosComponent implements OnInit {

  public proposals: Array<IProposalsListPropSimple> = [];

  constructor(
    private snackBar: MatSnackBar,
    private myErrorUtils: ErrorUtilsService,
    private http: HttpClient,
    private myHttpUtils: MyHttpUtils,
    private myGlobals: MyGlobalsService,
    private dialog: MatDialog,
    private myStringUtils: StringUtilsService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.loadProposals();
  }

  private loadProposals() {
    if(!this.myGlobals.personInfo || !this.myGlobals.personInfo.id) {
      return;
    }

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    this.http.get(`${this.myGlobals.baseUrlApi}/api/propostas?pessoa=${this.myGlobals.personInfo.id}&sort[id]=desc`, {
      headers: this.myHttpUtils.configBasicAuthHeader()
    })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.myErrorUtils.showHttpError(err.error);

          return this.myHttpUtils.breakObservableChain();
        })
      )
      .subscribe((data: IHttpResponseProposalsList) => {
        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

        if (data) {
          this.extractPropsInfo(data.contentResponse);
        }

      });
  }

  private extractPropsInfo(contentResponse: Array<IProposalsListProp>) {
    const numberFormatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });

    const contractEmptyStr = '-';

    for (let i = 0; i < contentResponse.length; ++i) {

      let contract = contentResponse[i]._links[1].body.contract + '';
      contract = contract && parseInt(contract) > 0 ? contract : contractEmptyStr;

      let pathDetails: IProposalsListPropLinks = {
        rel: '',
        path: '',
        body: {
          cpf: '',
          contract: 0
        }
      };

      pathDetails = contentResponse[i]._links[1];

      this.proposals.push({
        caminho_detalhes: pathDetails,
        contrato: contract,
        id_proposta_financeira_enviada: contentResponse[i].id_proposta_financeira_enviada,
        data_proposta: this.myStringUtils.formatAndConvertUsToBr(contentResponse[i].data_proposta.date),
        prazo: contentResponse[i].prazo,
        prestacao: numberFormatter.format(parseInt(contentResponse[i].prestacao)),
        valor: numberFormatter.format(parseInt(contentResponse[i].valor)),
        vencimento_inicial: this.myStringUtils.formatAndConvertUsToBr(contentResponse[i].vencimento_inicial.date),
        status: this.translateStatusProposal(contentResponse[i].status_proposta),
        status_css_classe: this.generateStatusProposalCssClass(contentResponse[i].status_proposta, contract != contractEmptyStr),
        fase: contentResponse[i].fase
      });
    }
  }

  private translateStatusProposal(status: string) {
    if (status === 'FilaAnalise') {
      return 'Analisando';
    }

    return status;
  }

  private generateStatusProposalCssClass(status: string, hasContract: boolean) {
    switch (status) {
      case "Liberada":
      case 'Aprovada':

        // if (!hasContract) {
        //   return 'analyzing';
        // }

        return 'approved';

      case 'FilaAnalise':
        return 'analyzing';

      case 'Negada':
        return 'notApproved';

      case 'Cancelada':
        return 'canceled';
    }

    throw new Error('Estado da proposta inválido');
  }

  public openContractDetails(id: number, cpf: string, path: string) {
    if (id === 0) {
      return;
    }

    this.router.navigate(['pedido/detalhes/', id, cpf], {
      queryParams: {
        path: path
      }
    });
  }

  refreshContent() {
    this.proposals = [];

    this.loadProposals();
  }
}
