import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {

  constructor() { }

  public configBirthDateMask() {

    const minDateRaw = moment().subtract(150, 'y');
    const maxDateRaw = moment().subtract(18, 'y');

    return {
      mask: Date,
      min: new Date(parseInt(minDateRaw.format('YYYY')), parseInt(minDateRaw.format('MM')), parseInt(minDateRaw.format('DD'))),
      max: new Date(parseInt(maxDateRaw.format('YYYY')), parseInt(maxDateRaw.format('MM')), parseInt(maxDateRaw.format('DD'))),
      pattern: 'd.m.Y',
    };
  }

  public configAdmissionDateMask() {
    const minDateRaw = moment().subtract(150, 'y');
    const maxDateRaw = moment().subtract(0, 'y');

    return {
      mask: Date,
      min: new Date(parseInt(minDateRaw.format('YYYY')), parseInt(minDateRaw.format('MM')), parseInt(minDateRaw.format('DD'))),
      max: new Date(parseInt(maxDateRaw.format('YYYY')), parseInt(maxDateRaw.format('MM')), parseInt(maxDateRaw.format('DD'))),
      pattern: 'd.m.Y',
    };
  }
}
