import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {InfoBancoComponent} from './info-banco.component';
import {PageTitleModule} from '../../../components/page-title/page-title.module';
import {ReactiveFormsModule} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';



@NgModule({
  declarations: [InfoBancoComponent],
    imports: [
        CommonModule,
        PageTitleModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule
    ],
  exports: [InfoBancoComponent]
})
export class InfoBancoModule { }
