import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PedidoComponent } from '../pedido/pedido.component';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [PedidoComponent],
    imports: [
        CommonModule,
        RouterModule
    ]
})
export class PedidoModule { }
