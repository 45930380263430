<div class="ValidaToken">
  <div class="ValidaToken-wrapper">

    <app-page-title>Valide seu token</app-page-title>

    <p class="ValidaToken-descricao">Foi enviado para seu e-mail um token, digite ele abaixo:</p>

    <form [formGroup]="tokenForm" (submit)="submitToken();">

      <mat-form-field class="fullWidth">
        <input
          matInput
          type="tel"
          placeholder="Token"
          formControlName="token"
        >

        <mat-error *ngIf="tokenForm.get('token').hasError('required')">
          Campo obrigatório
        </mat-error>

      </mat-form-field>

      <div class="ValidaToken-buttonWrapper">
        <button type="button" class="ValidaToken-button--cancel" mat-raised-button (click)="cancel();" color="primary">Cancelar</button>
        <button class="ValidaToken-button--submit" mat-raised-button color="accent">Enviar</button>
      </div>

    </form>

  </div>
</div>
