<div class="NovoCliente">
  <div class="NovoCliente-wrapper">

    <app-page-title>Cadastre-se</app-page-title>

    <div class="NovoCliente-formWrapper">
      <div class="NovoCliente-login">
        <a [routerLink]="['/login']">Se você já é nosso cliente <span>clique aqui</span>,</a>
      </div>
      <p>caso você seja um cliente novo, preencha o formulário para continuar!</p>

      <form [formGroup]="clientInfo" (submit)="submitClientInfo();" novalidate>

        <mat-form-field class="fullWidth">
          <input
            matInput
            type="tel"
            placeholder="CPF"
            name="cpf"
            formControlName="cpf"
            [imask]="cpfMask"
          >

          <mat-error *ngIf="clientInfo.get('cpf').hasError('cpf') && !clientInfo.get('cpf').hasError('required')">
            CPF inválido
          </mat-error>

          <mat-error *ngIf="clientInfo.get('cpf').hasError('required')">
            Campo obrigatório
          </mat-error>

        </mat-form-field>

        <mat-form-field class="fullWidth">
          <input
            matInput
            type="text"
            placeholder="Nome"
            name="name"
            formControlName="name"
          >

          <mat-error *ngIf="clientInfo.get('name').hasError('required')">
            Campo obrigatório
          </mat-error>

        </mat-form-field>

        <mat-form-field class="fullWidth">
          <input
            matInput
            placeholder="E-mail"
            name="email"
            formControlName="email"
            type="email"
          >

          <mat-error *ngIf="clientInfo.get('email').hasError('email') && !clientInfo.get('email').hasError('required')">
            E-mail inválido
          </mat-error>

          <mat-error *ngIf="clientInfo.get('email').hasError('required')">
            Campo obrigatório
          </mat-error>

        </mat-form-field>

        <mat-form-field class="fullWidth">
          <input
            matInput
            type="tel"
            placeholder="Celular"
            name="cellphone"
            formControlName="cellphone"
            [imask]="telMask"
          >

          <mat-error *ngIf="clientInfo.get('cellphone').hasError('cellphone') && !clientInfo.get('cellphone').hasError('required')">
            Celular inválido
          </mat-error>

          <mat-error *ngIf="clientInfo.get('cellphone').hasError('required')">
            Campo obrigatório
          </mat-error>

        </mat-form-field>

        <mat-form-field class="fullWidth">
          <input
            matInput
            type="tel"
            placeholder="Data de Nascimento"
            name="birthDate"
            formControlName="birthDate"
            [imask]="dateMask"
          >

          <mat-error *ngIf="clientInfo.get('birthDate').hasError('required')">
            Campo obrigatório
          </mat-error>

        </mat-form-field>

        <mat-form-field class="fullWidth">
          <input
            matInput
            type="password"
            placeholder="Senha"
            name="pass1"
            formControlName="pass1"
          >

          <mat-error *ngIf="clientInfo.get('pass1').hasError('required')">
            Campo obrigatório
          </mat-error>

        </mat-form-field>

        <mat-form-field class="fullWidth">
          <input
            matInput
            type="password"
            placeholder="Confirmação de Senha"
            name="pass2"
            formControlName="pass2"
          >

          <mat-error *ngIf="clientInfo.get('pass2').hasError('required')">
            Campo obrigatório
          </mat-error>

        </mat-form-field>

        <div class="NovoCLiente-useTerms">
          <mat-checkbox name="accept" formControlName="accept">Li e aceito os </mat-checkbox>
          <button type="button" mat-flat-button (click)="openUserTermsDialog()"> termos de uso.</button>
        </div>

        <div class="NovoCliente-buttonWrapper">
<!--          <button class="NovoCliente-button--submit" mat-raised-button [disabled]="!this.clientInfo.valid">Enviar</button>-->
          <button class="NovoCliente-button--submit" mat-raised-button color="accent">Enviar</button>
        </div>

      </form>
    </div>

  </div>
</div>
