<div class="InfoVendedor">
  <app-page-title>Um vendedor está ajudando você?</app-page-title>

  <form [formGroup]="mainForm" (submit)="nextPage();" novalidate>

    <mat-radio-group class="fullWidth" formControlName="hasSeller">
      <mat-radio-button [value]="true">Sim</mat-radio-button>
      <mat-radio-button [value]="false">Não</mat-radio-button>
    </mat-radio-group>

    <div class="InfoVendedor-hasSeller" *ngIf="hasSeller">
      <mat-form-field [hideRequiredMarker]="true" class="fullWidth">

        <input type="text"
               matInput
               placeholder="Nome da loja"
               formControlName="storeName"
               [matAutocomplete]="autoStore">

        <mat-autocomplete #autoStore="matAutocomplete">
          <mat-option *ngFor="let store of storeDataOptions | async" [value]="store.nome">
            {{store.nome}}
          </mat-option>
        </mat-autocomplete>

        <mat-error *ngIf="mainForm.get('storeName').hasError('required')">
          Campo obrigatório
        </mat-error>

      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" class="fullWidth">

        <input type="text"
               matInput
               placeholder="Nome do Vendedor"
               formControlName="sellerName"
               [matAutocomplete]="autoSeller">

        <mat-autocomplete #autoSeller="matAutocomplete">
          <mat-option *ngFor="let seller of sellerDataOptions | async" [value]="seller.nome_agemte">
            {{seller.nome_agemte}}
          </mat-option>
        </mat-autocomplete>

        <mat-error *ngIf="mainForm.get('sellerName').hasError('required')">
          Campo obrigatório
        </mat-error>

      </mat-form-field>
    </div>

    <div class="InfoVendedor-buttonsWrapper flex-end">
      <button mat-raised-button color="accent" [disabled]="blockContinueButton">Continuar</button>
    </div>

  </form>

</div>
