export interface ICameraOptions {
  showFrame: boolean;
  constraints: ICameraContraints;
  videoElem: HTMLVideoElement;
  canvasElem: HTMLCanvasElement;
}

export enum ECameraOrientation {
  Portrait = 'portrait',
  Landscape = 'landscape'
}

export enum ECameraFacingMode {
  Environment = 'environment',
  User = 'user'
}

export interface ICameraContraints {
  video: MediaTrackConstraints;
}
