import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {MyGlobalsService} from '../services/my-globals/my-globals.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProposalService} from '../pages/emprestimo/proposal.service';
import {LocalStorageService} from "../services/local-storage.service";
import {ILoginInfoDb} from "../pages/login/definitions";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private myGlobals: MyGlobalsService,
    private snackBar: MatSnackBar,
    private proposalData: ProposalService,
    private localStorage: LocalStorageService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if(next.url[0].path == 'emprestimo' && state.url != '/emprestimo/estados-permitidos' && !this.proposalData.loanData.aceito) {
      return this.router.navigate(['/login'], {});
    }

    return this.generic();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.generic();
  }

  private generic() {

    const storageData: ILoginInfoDb = this.localStorage.get('userData');

    if (!storageData) {
      this.router.navigate(['/login'], {});

      return new Observable<boolean>(obs=> obs.next(false));
    }

    this.myGlobals.userToken = storageData.userToken;
    this.myGlobals.userInfo = storageData.userInfo;
    this.myGlobals.personInfo = storageData.personInfo;

    if (!this.myGlobals.userToken) {
      this.snackBar.open('É necessário fazer o login', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--warning'],
      });

      this.router.navigate(['/login'], {});

      return new Observable<boolean>(obs=> obs.next(false));
    }

    return new Observable<boolean>(obs=> obs.next(true));

  }

}
