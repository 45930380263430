import {Component, OnInit} from '@angular/core';
import {ProposalService} from '../proposal.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {ISellerData, ISellerListHttpResponse, IStoreData, IStoreListHttpResponse} from './definition';
import {catchError, map, startWith} from 'rxjs/operators';
import {EPageLoaderStatus} from '../../../services/my-globals/definitions';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {MyHttpUtils} from '../../../services/utils/HttpUtils';
import {ErrorUtilsService} from '../../../services/utils/error/error-utils.service';

@Component({
  selector: 'app-info-vendedor',
  templateUrl: './info-vendedor.component.html',
  styleUrls: ['./info-vendedor.component.scss']
})
export class InfoVendedorComponent implements OnInit {

  public mainForm: FormGroup;

  public storeDataOptions: Observable<IStoreData[]>;
  public storeData: IStoreData[];

  public sellerDataOptions: Observable<ISellerData[]>;
  public sellerData: ISellerData[];
  public sellerDataFiltered: ISellerData[];

  public hasSeller = false;

  public blockContinueButton = true;

  constructor(
    private proposalData: ProposalService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private myGlobals: MyGlobalsService,
    private http: HttpClient,
    private myHttpUtils: MyHttpUtils,
    private myErrorUtils: ErrorUtilsService,
  ) {
  }

  ngOnInit(): void {
    this.initForm();

    this.loadStoresAndSellers();
  }

  nextPage(): void {
    if (!this.mainForm.valid) {
      this.snackBar.open('Faltou preencher os campos obrigatórios', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    if (this.hasSeller) {
      const sellerId = this.getSellerIdFromSelected();

      if (!sellerId) {
        this.snackBar.open('Nome do vendedor inválido, por favor, selecione da lista.', 'ok', {
          duration: 5000,
          panelClass: ['MySnackBar', 'MySnackBar--error'],
        });

        return;
      }

      const loanData = this.proposalData.loanData;
      loanData.agente_vendas_id = sellerId;
      this.proposalData.setLoanInfo(loanData);
    }

    this.router.navigate(['emprestimo/info-emprestimo'], {});
  }

  private getSellerIdFromSelected(): number {
    const sellerName = this.mainForm.get('sellerName').value;

    return this.sellerData.find((val: ISellerData) => {
      return val.nome_agemte == sellerName;
    }).id;
  }

  private initForm() {
    this.mainForm = this.formBuilder.group({
      hasSeller: ['', [Validators.required]],
      storeName: ['', []],
      sellerName: ['', []],
    });

    this.storeDataOptions = this.mainForm.get('storeName').valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterStore(value))
      );

    this.sellerDataOptions = this.mainForm.get('sellerName').valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterSeller(value))
      );

    this.mainForm.get('hasSeller').valueChanges
      .pipe(
        startWith('')
      )
      .subscribe(value => {
        this.hasSeller = value;

        const storeNameField = this.mainForm.get('storeName');
        const sellerNameField = this.mainForm.get('sellerName');

        storeNameField.clearValidators();
        sellerNameField.clearValidators();

        if (value) {
          storeNameField.setValidators([Validators.required]);
          sellerNameField.setValidators([Validators.required]);
        }

        storeNameField.updateValueAndValidity();
        sellerNameField.updateValueAndValidity();

        this.blockContinueButton = false;
      });
  }

  private filterStore(value: string): IStoreData[] {
    if (value) {
      const storeData = this.storeData.filter(storeValue => {
        return storeValue.nome.toLowerCase().search(value.toLowerCase()) >= 0;
      });

      this.sellerDataFiltered = this.sellerData.filter(sellerValue => {
        const userStoreName: string = this.mainForm.get('storeName').value.toLowerCase();

        return sellerValue.loja_nome.toLocaleLowerCase().search(userStoreName) >= 0;
      });

      this.mainForm.get('sellerName').enable();

      return storeData;
    }

    return this.storeData;
  }

  private filterSeller(value: string): ISellerData[] {
    return this.sellerDataFiltered.filter(sellerValue => {
      const userStoreName: string = this.mainForm.get('storeName').value.toLowerCase();

      const IsUserStore = sellerValue.loja_nome.toLocaleLowerCase().search(userStoreName) >= 0;

      if (value) {
        return sellerValue.nome_agemte.toLowerCase().search(value.toLowerCase()) >= 0 && IsUserStore;
      }

      return IsUserStore;
    });
  }

  private loadStoresAndSellers(): void {
    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    this.http.get(`${this.myGlobals.baseUrlApi}/api/agente_vendas?page=1&perPage=500`, {
      headers: this.myHttpUtils.configBasicAuthHeader()
    })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.myErrorUtils.showHttpError(err.error);

          return this.myHttpUtils.breakObservableChain();
        })
      )
      .subscribe((resp: ISellerListHttpResponse) => {
        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

        this.storeData = this.processStore(resp.contentResponse);
        this.sellerDataFiltered = this.sellerData = this.processSeller(resp.contentResponse);
      });
  }

  // private loadSellers(): void {
  //   this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);
  //
  //   this.http.get(`${this.myGlobals.baseUrlApi}/`)
  //     .pipe(
  //       catchError((err: HttpErrorResponse) => {
  //         this.myErrorUtils.showHttpError(err.error);
  //
  //         return this.myHttpUtils.breakObservableChain();
  //       })
  //     )
  //     .subscribe((resp: any) => {
  //       this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);
  //       // TODO carregar as lojas para o autocomplete.
  //     });
  // }

  private processStore(data: Array<ISellerData>) {
    const result: Array<IStoreData> = [];
    const map = new Map();

    for (const item of data) {
      if (!map.has(item.loja_id)) {
        map.set(item.loja_id, true);
        result.push({
          id: item.loja_id,
          nome: `${item.loja_id} - ${item.loja_nome}`
        });
      }
    }

    return result;
  }

  private processSeller(data: Array<ISellerData>) {
    const result: Array<ISellerData> = [];
    const map = new Map();

    for (const item of data) {
      if (!map.has(item.id)) {
        map.set(item.id, true);

        item.loja_nome = `${item.loja_id} - ${item.loja_nome}`;
        item.nome_agemte = `${item.id} - ${item.nome_agemte}`;

        result.push(item);
      }
    }

    return result;
  }
}
