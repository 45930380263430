<div class="PedidosLista">
  <div class="PedidosLista-wrapper">

    <app-page-title class="PedidosLista-title">Pedidos</app-page-title>
    <p class="PedidosLista-descricao">Todos os seus pedidos. </p>
    <button mat-mini-fab class="PedidosLista-refresh" (click)="refreshContent()">
      <i class="icofont-refresh"></i>
    </button>

    <div class="PedidosLista-lista">
      <mat-card (click)="openContractDetails(item.caminho_detalhes.body.contract, item.caminho_detalhes.body.cpf, item.caminho_detalhes.path)"
                *ngFor="let item of proposals"
                [matRippleDisabled]="item.status_css_classe !== 'approved'"
                class="PedidosLista-card {{item.status_css_classe}}"
                matRipple
      >
        <p>Situação: <strong class="PedidosLista-status"><span class="PedidosLista-cardStatus"></span> {{item.status}}</strong></p>
        <p>Fase: <strong>{{item.fase}}</strong></p>
        <p>Pedido: <strong>{{item.id_proposta_financeira_enviada}}</strong></p>
        <p>Data: <strong>{{item.data_proposta}}</strong></p>
        <p>Financiado: <strong>{{item.valor}}</strong></p>
        <p>Parcela: <strong>{{item.prazo}}x {{item.prestacao}}</strong></p>
        <p *ngIf="item.status_css_classe === 'approved'" class="PedidosLista-modeDetails">Clique para mais detalhes</p>
      </mat-card>
    </div>

  </div>
</div>
