import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoVendedorComponent } from './info-vendedor.component';
import {PageTitleModule} from '../../../components/page-title/page-title.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';



@NgModule({
  declarations: [InfoVendedorComponent],
  imports: [
    CommonModule,
    PageTitleModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatButtonModule
  ]
})
export class InfoVendedorModule { }
