import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {cpfValidator} from '../../../form-validators/cpf.validator.directive';
import {INewClientResponse} from './definitions';
import * as moment from 'moment';
import {UserTermsDialogComponent} from '../../../components/dialogs/user-terms-dialog/user-terms-dialog.component';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import {catchError} from 'rxjs/operators';
import {MyHttpUtils} from '../../../services/utils/HttpUtils';
import {StringUtilsService} from '../../../services/utils/string-utils.service';
import {CpfMask, EPageLoaderStatus, TelMask} from '../../../services/my-globals/definitions';
import {cellphoneValidator} from '../../../form-validators/cellphone.validator.directive';
import {DateUtilsService} from '../../../services/utils/date-utils.service';
import {ErrorUtilsService} from '../../../services/utils/error/error-utils.service';

@Component({
  selector: 'app-novo-cliente',
  templateUrl: './novo-cliente.component.html',
  styleUrls: ['./novo-cliente.component.scss']
})
export class NovoClienteComponent implements OnInit {
  public clientInfo: FormGroup;
  public cpfMask = CpfMask;
  public telMask = TelMask;
  public dateMask = {};

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public dialog: MatDialog,
    private myGlobals: MyGlobalsService,
    private myHttpUtils: MyHttpUtils,
    private myStringUtils: StringUtilsService,
    private dateUtils: DateUtilsService,
    private errorUtils: ErrorUtilsService
  ) {
  }

  public ngOnInit() {
    this.initForm();
  }

  public submitClientInfo() {
    if(!this.clientInfo.valid) {
      this.snackBar.open('Faltou preencher os campos obrigatórios', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    const pass1 = this.clientInfo.get('pass1').value;
    const pass2 = this.clientInfo.get('pass2').value;

    if (pass1 != pass2) {
      this.snackBar.open('As senhas não são iguais.', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    this.http.post(`${this.myGlobals.baseUrlApi}/register`, {
      'fullName': this.clientInfo.get('name').value,
      'bithDate': this.myStringUtils.formatToDateBr(this.clientInfo.get('birthDate').value),
      'phone': this.myStringUtils.removeTelMask(this.clientInfo.get('cellphone').value),
      'cpf': this.myStringUtils.removeCpfMask(this.clientInfo.get('cpf').value),
      'username': this.clientInfo.get('email').value,
      'password': this.clientInfo.get('pass1').value,
      'terms_of_use': this.myGlobals.useTerms
    })
      .pipe(
        catchError((err: HttpErrorResponse) => {

          if(err.error.contentResponse.errorsFields) {
            this.errorUtils.showHttpError(err.error.contentResponse.fields[0]);
          } else {
            this.errorUtils.showHttpError(err.error.contentResponse);
          }

          return this.myHttpUtils.breakObservableChain();
        })
      )
      .subscribe((response: INewClientResponse) => {
        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

        this.myGlobals.userInfo = response.contentResponse;

        this.router.navigate(['validar-token'], {});
      });
  }

  private initForm() {

    this.dateMask = this.dateUtils.configBirthDateMask();

    this.clientInfo = this.formBuilder.group({
      name: ['', [
        Validators.required
      ]],
      birthDate: ['', [
        Validators.required
      ]],
      cellphone: ['', [
        Validators.required,
        cellphoneValidator
      ]],
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      cpf: ['', [
        Validators.required,
        cpfValidator
      ]],
      accept: ['', [
        Validators.required
      ]],
      pass1: ['', [
        Validators.required
      ]],
      pass2: ['', [
        Validators.required
      ]]
    });
  }

  public openUserTermsDialog() {
    const dialogRef = this.dialog.open(UserTermsDialogComponent, {
      hasBackdrop: true,
      height: '100vh',
      minWidth: '340px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
