import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EmprestimoComponent} from '../emprestimo.component';
import {InfoContatoComponent} from './info-contato.component';
import {PageTitleModule} from '../../../components/page-title/page-title.module';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {IMaskModule} from 'angular-imask';

@NgModule({
  declarations: [InfoContatoComponent],
  imports: [
    CommonModule,
    PageTitleModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    IMaskModule
  ],
  exports: [InfoContatoComponent]
})
export class InfoContatoModule { }
