import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateUtilsService} from '../../../services/utils/date-utils.service';
import {RgMask, BrazilStatesList, TelMask, CivilState} from '../../../services/my-globals/definitions';
import {ProposalService} from '../proposal.service';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import {IPersonalInfo} from '../definitions';
import {Moment} from 'moment';
import {StringUtilsService} from '../../../services/utils/string-utils.service';
import {dateValidator} from '../../../form-validators/date.validator.directive';

@Component({
  selector: 'app-info-pessoal',
  templateUrl: './info-pessoal.component.html',
  styleUrls: ['./info-pessoal.component.scss']
})
export class InfoPessoalComponent implements OnInit, AfterViewInit {

  public mainForm: FormGroup;
  public dateMask = {};
  public telMask = TelMask;
  public brazilStates = BrazilStatesList;
  public rgMask = RgMask;
  public civilState = CivilState;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private dateUtils: DateUtilsService,
    private proposalData: ProposalService,
    private myGlobals: MyGlobalsService,
    private myStringUtils: StringUtilsService
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateForm();
    }, 0);
  }

  nextPage() {
    if (!this.mainForm.valid) {
      this.snackBar.open('Faltou preencher os campos obrigatórios', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    const formData: IPersonalInfo = this.mainForm.value;
    formData.cpf = this.myGlobals.userInfo.cpf;

    this.proposalData.setPersonalData(formData);

    this.router.navigate(['emprestimo/info-contato'], {});

  }

  private initForm() {
    this.dateMask = this.dateUtils.configBirthDateMask();

    this.mainForm = this.formBuilder.group({
      nome: ['', [Validators.required]],
      dataNascimento: ['', [
        Validators.required,
        dateValidator
      ]],
      sexo: ['', [Validators.required]],
      rg: ['', [Validators.required]],
      rgOrgaoEmissor: ['', [Validators.required]],
      rgUf: ['', [Validators.required]],
      estadoCivil: ['', [Validators.required]],
      referenciaNome: ['', [Validators.required]],
      referenciaTelefone: ['', [Validators.required]]
    });
  }

  private updateForm() {
    const person = this.myGlobals.personInfo;

    if (!person) {
      return;
    }

    let date = person.dataNascimento.date.split(' ')[0];
    date = this.myStringUtils.convertDateUsToBr(date);

    this.mainForm.setValue({
      nome: person.nome,
      dataNascimento: date,
      sexo: person.sexo,
      rg: person.rg,
      rgOrgaoEmissor: person.rgOrgaoEmissor,
      rgUf: person.rgUf,
      estadoCivil: person.estadoCivil + '',
      referenciaNome: person.referenciaNome ? person.referenciaNome : '',
      referenciaTelefone: person.referenciaTelefone ? person.referenciaTelefone : '',
    });
  }
}
