import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import {MyHttpUtils} from '../../../services/utils/HttpUtils';
import {catchError, concatMap} from 'rxjs/operators';
import {ErrorUtilsService} from '../../../services/utils/error/error-utils.service';
import {IServerError} from '../../../services/utils/error/definitions';
import {IContractDetails, IContractDetailsHttpResponse, IContractDetailsInstallment} from './definitions';
import {EPageLoaderStatus} from '../../../services/my-globals/definitions';

@Component({
  selector: 'app-detalhes-pedido',
  templateUrl: './detalhes-pedido.component.html',
  styleUrls: ['./detalhes-pedido.component.scss']
})
export class DetalhesPedidoComponent implements OnInit {

  public contractDetails: IContractDetails;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private myGlobals: MyGlobalsService,
    private myErrorUtils: ErrorUtilsService,
    private myHttpUtils: MyHttpUtils
  ) {
  }

  ngOnInit() {

    let contractDetailsHttpData = {
      cpf: '',
      contract: 0
    };

    this.activatedRoute.paramMap
      .pipe(
        concatMap((data: ParamMap) => {
          contractDetailsHttpData.cpf = data.get('cpf');
          contractDetailsHttpData.contract = parseInt(data.get('id'));

          return this.activatedRoute.queryParamMap;
        }))
      .subscribe((data: ParamMap) => {
      this.loadDetails(contractDetailsHttpData.cpf, contractDetailsHttpData.contract, data.get('path'));
    });
  }

  gotToListaPedidos() {
    this.router.navigate(['/pedido/lista']);
  }

  copyBarCode(barCode: string) {
    const el = document.createElement('textarea');
    el.value = barCode.replace(/[. ]/g, '');
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.snackBar.open('Código de barras copiado.', 'ok', {
      duration: 2500,
      panelClass: ['MySnackBar', 'MySnackBar--success'],
    });
  }

  private loadDetails(cpf: string, id: number, path: string) {
    const data = {
      cpf: cpf,
      contract: id
    };

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    this.http.post(`${this.myGlobals.baseUrlApi}/api${path}`, data, {
      headers: this.myHttpUtils.configBasicAuthHeader()
    })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if(err.error['status'] === 204) {
            this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

            this.snackBar.open('O contrato ainda está sendo processado pelo sistema, após 36 horas estará disponível.', 'ok', {
              duration: 15000,
              panelClass: ['MySnackBar', 'MySnackBar--success'],
            });

            this.gotToListaPedidos();

            return this.myHttpUtils.breakObservableChain();
          }

          this.myErrorUtils.showHttpError(err.error);

          this.gotToListaPedidos();

          return this.myHttpUtils.breakObservableChain();
        })
      )
      .subscribe((data: IContractDetailsHttpResponse) => {
        this.contractDetails = this.formatContractDetails(data.contentResponse.Registros[0]);

        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);
      });
  }

  private formatContractDetails(res: IContractDetails) {
    const numberFormatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });

    res.ValorFinanciadoFrm = numberFormatter.format(res.ValorFinanciado);
    res.ValorPrestacaoFrm = numberFormatter.format(res.ValorPrestacao);
    res.Atraso = Math.max(res.Atraso, 0);

    res.Parcelas = res.Parcelas.map((val, index) => {
      val.ValorFrm = numberFormatter.format(val.Valor);
      val.Situacao = val.Situacao === 'Liquidado' ? 'Pago' : val.Situacao;

      return val;
    });

    return res;
  }

  installmentSituationCss(item: IContractDetailsInstallment) {
    return {
      paid: item.Situacao === 'Pago',
      toPaid: item.Situacao === 'A Vencer',
      notPaid: item.Atraso > 0 && item.Situacao !== 'Pago'
    };
  }
}
