import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {cellphoneValidator} from '../../../form-validators/cellphone.validator.directive';
import {ProposalService} from '../proposal.service';
import {FormUtilsService} from '../../../services/utils/form-utils.service';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';

@Component({
  selector: 'app-info-parentes',
  templateUrl: './info-parentes.component.html',
  styleUrls: ['./info-parentes.component.scss']
})
export class InfoParentesComponent implements OnInit, AfterViewInit {

  public mainForm: FormGroup;

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router,
    private myFormUtils: FormUtilsService,
    private myGlobals: MyGlobalsService,
    private proposalData: ProposalService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateForm();
    }, 0);
  }

  nextPage() {
    if(!this.mainForm.valid) {
      this.snackBar.open('Faltou preencher os campos obrigatórios', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    this.proposalData.setParentsData(this.mainForm.value);

    this.router.navigate(['emprestimo/info-trabalho'], {});

  }

  private initForm() {
    this.mainForm = this.formBuilder.group({
      nomeMae: ['', [
        Validators.required
      ]],
      nomePai: ['', [
        Validators.required
      ]],
    });
  }

  private updateForm() {
    const person = this.myGlobals.personInfo;

    if (!person) {
      return;
    }

    this.mainForm.setValue({
      nomeMae: person.nomeMae,
      nomePai: person.nomePai,
    });
  }
}
