import { Component, OnInit } from '@angular/core';
import {PageTransitionAnim} from '../../animations/page-transition';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  animations: [PageTransitionAnim],
  styleUrls: ['./pedido.component.scss']
})
export class PedidoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  getRouterOutletState(outlet: any) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
}
