<div class="FinalStep">

  <div *ngIf="awaiting" class="FinalStep-center awaiting">
    <i class="icofont-like"></i>

    <h1>Seu pedido foi enviado!</h1>
    <p>Estamos analisando agora o seu pedido e logo enviaremos no seu e-mail se foi aprovado.</p>

    <button mat-raised-button (click)="goToMeusPedidos()" color="accent">Ver meus pedidos</button>

  </div>

  <div *ngIf="denied" class="FinalStep-center noNext">
    <i class="icofont-sad"></i>

    <h1>Não foi desta vez!</h1>
    <p>Infelizmente no momento não podemos atender o seu pedido.</p>

  </div>

</div>
