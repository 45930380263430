import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from '../../route-guard/auth.guard';
import {NovoClienteComponent} from './novo-cliente/novo-cliente.component';
import {ValidaTokenComponent} from './valida-token/valida-token.component';
import {PerfilComponent} from './perfil/perfil.component';
import {NovoClienteModule} from './novo-cliente/novo-cliente.module';
import {PerfilModule} from './perfil/perfil.module';
import {ValidaTokenModule} from './valida-token/valida-token.module';
import {UsernameGuard} from '../../route-guard/username.guard';


const routes: Routes = [
  {
    path: 'novo-cliente',
    component: NovoClienteComponent,
  },
  {
    path: 'validar-token',
    canActivate: [UsernameGuard],
    component: ValidaTokenComponent,
  },
  {
    path: 'perfil',
    canActivate: [AuthGuard],
    component: PerfilComponent,
  },
];

@NgModule({
  imports: [
    NovoClienteModule,
    PerfilModule,
    ValidaTokenModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class ClienteRoutingModule {
}
