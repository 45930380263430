import {Injectable} from '@angular/core';
import {IGenericHttpError} from '../../definitions';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class FormUtilsService {

  constructor(
    private snackBar: MatSnackBar,
  ) {
  }

  public showGenericErrorMessage() {
    this.snackBar.open('Um ou mais campos do formulário contém um erro.', 'ok', {
      duration: 5000,
      panelClass: ['MySnackBar', 'MySnackBar--error'],
    });
  }

  public getEmptyOrData(obj: any, key: string): any {
    if(!obj || !obj[key]) {
      return '';
    }

    return obj[key];
  }
}
