import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EProposalStatus} from "./definitions";

@Component({
  selector: 'app-final-step',
  templateUrl: './final-step.component.html',
  styleUrls: ['./final-step.component.scss']
})
export class FinalStepComponent {
  awaiting: boolean = false;
  denied: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      const status: EProposalStatus = params['status'] as EProposalStatus;

      if(status === EProposalStatus.Aguardando) {
        this.awaiting = true;
        this.denied = false;
      } else if(status === EProposalStatus.NaoProssegue) {
        this.awaiting = false;
        this.denied = true;
      }
    });
  }

  goToMeusPedidos() {
    this.router.navigate(['pedido/lista'])
  }
}
