import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {ListaPedidosComponent} from './pages/pedido/lista-pedidos/lista-pedidos.component';
import {LoginModule} from './pages/login/login.module';
import {ListaPedidosModule} from './pages/pedido/lista-pedidos/lista-pedidos.module';
import {AuthGuard} from './route-guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'novo-cliente',
    pathMatch: 'full'
  },
  {
    path: 'app',
    redirectTo: 'novo-cliente',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'pedidos-lista',
    canActivate: [AuthGuard],
    component: ListaPedidosComponent
  },
  {
    path: 'identification-link',
    loadChildren: () => import('./pages/identification-link/identification-link.module').then(m => m.IdentificationLinkModule)
  }
];

@NgModule({
  imports: [
    LoginModule,
    ListaPedidosModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
