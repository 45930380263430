import {Component, OnInit} from '@angular/core';
import {MyGlobalsService} from '../../services/my-globals/my-globals.service';
import {Router} from '@angular/router';
import {EMainMenuStatus} from '../../services/my-globals/definitions';
import {LocalStorageService} from "../../services/local-storage.service";

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  public appVersion = '';

  constructor(
    private router: Router,
    private myGlobals: MyGlobalsService,
    private localStorage: LocalStorageService
  ) {
    this.appVersion = myGlobals.appVersion;
  }

  public ngOnInit() {
  }

  public logout() {
    this.myGlobals.userInfo = null;
    this.localStorage.clear();
    this.router.navigate(['/login'], {});
    this.closeMainMenu();
  }

  public closeMainMenu() {
    this.myGlobals.setGlobalMainMenuStatus(EMainMenuStatus.hidden);
  }
}
