<div class="Login">

  <mat-card class="Login-wrapper">
    <app-page-title class="Login-title">Login</app-page-title>

    <div class="Login-formWrapper">

      <form *ngIf="showLogin" class="Login-loginForm" [formGroup]="loginForm" (submit)="submitLogin();">

        <mat-form-field class="fullWidth">
          <input type="email" matInput placeholder="E-mail" formControlName="email">

          <mat-error *ngIf="loginForm.get('email').hasError('email') && !loginForm.get('email').hasError('required')">
            E-mail inválido
          </mat-error>

          <mat-error *ngIf="loginForm.get('email').hasError('required')">
            Campo obrigatório
          </mat-error>

        </mat-form-field>

        <mat-form-field class="fullWidth">
          <input
            matInput
            type="password"
            placeholder="Senha"
            formControlName="password"
          >

          <mat-error *ngIf="loginForm.get('password').hasError('required')">
            Campo obrigatório
          </mat-error>

        </mat-form-field>

        <div class="Login-buttonWrapper">
          <a class="Login-esqueciSenha" href="#" (click)="showLogin = false">Esqueci minha senha</a>

          <button class="Login-button--submit" mat-raised-button [disabled]="!loginForm.valid" color="accent">Entrar</button>
        </div>

      </form>

      <form *ngIf="!showLogin" class="Login-recoveryForm" [formGroup]="recoveryForm" (submit)="submitRecovery();">

        <p class="Login-recoveryFormDescription">Para recuperar a senha digite seu e-email abaixo.</p>

        <mat-form-field class="fullWidth">
          <input matInput placeholder="E-mail" formControlName="email">

          <mat-error *ngIf="recoveryForm.get('email').hasError('email') && !recoveryForm.get('email').hasError('required')">
            E-mail inválido
          </mat-error>

          <mat-error *ngIf="recoveryForm.get('email').hasError('required')">
            Campo obrigatório
          </mat-error>

        </mat-form-field>

        <div class="Login-buttonWrapper">
          <button type="button" class="Login-button--back" mat-stroked-button (click)="showLogin = true">
            <i class="icofont-arrow-left"></i>
            voltar
          </button>

          <button class="Login-button--submit" mat-raised-button [disabled]="!recoveryForm.valid" color="accent">Enviar</button>
        </div>

      </form>

    </div>

    <div class="Login-newClient">
      <a [routerLink]="['/novo-cliente']">Caso ainda não tenha cadastro <span>clique aqui</span>.</a>
    </div>

  </mat-card>

</div>
