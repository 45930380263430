<div class="DocPhotoBlock">
  <div class="DocPhotoBlock-document mat-elevation-z3">
    <p><strong>{{name}}</strong></p>

    <div class="DocPhotoBlock-documentImage" [ngStyle]="{backgroundImage: 'url('+photo+')'}"
         (click)="onClickFullScreen()"></div>

    <div class="DocPhotoBlock-documentFooter">
      <button mat-icon-button color="warn" class="DocPhotoBlock-button" (click)="onClickDelete()" *ngIf="showDelete">
        <i class="icofont-bin"></i>
      </button>

      <button mat-icon-button
              class="DocPhotoBlock-button DocPhotoBlock-button--fullscreen"
              (click)="onClickFullScreen()">
        <i class="icofont-expand"></i>
      </button>
    </div>

  </div>
</div>
