<div class="App">

  <app-main-header>
    <div class="App-routerOutletWrapper" [@PageTransitionAnim]="getRouterOutletState(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>

  </app-main-header>

  <app-page-loader *ngIf="showPageLoader"></app-page-loader>

</div>
