import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PedidoComponent} from './pedido.component';
import {AuthGuard} from '../../route-guard/auth.guard';
import {ListaPedidosComponent} from './lista-pedidos/lista-pedidos.component';
import {DetalhesPedidoComponent} from './detalhes-pedido/detalhes-pedido.component';
import {PedidoModule} from './pedido.module';
import {ListaPedidosModule} from './lista-pedidos/lista-pedidos.module';
import {DetalhesPedidoModule} from './detalhes-pedido/detalhes-pedido.module';

const routes: Routes = [
  {
    path: 'pedido',
    component: PedidoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'lista',
        component: ListaPedidosComponent
      },
      {
        path: 'detalhes/:id/:cpf',
        component: DetalhesPedidoComponent
      }
    ]
  },
];

@NgModule({
  imports: [
    PedidoModule,
    ListaPedidosModule,
    DetalhesPedidoModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class PedidoRoutingModule {
}
