<div class="ConfirmPhoto">
  <h1 class="ConfirmPhoto-title" mat-dialog-title>
    Deseja usar esta foto?
  </h1>

  <div class="ConfirmPhoto-content">
    <img [ngClass]="{landscape: isLandscape}" class="ConfirmPhoto-image" src="{{photo}}"/>

    <div class="ConfirmPhoto-errorMsg" *ngIf="photoError !== ''">
      <p>{{photoError}}</p>
    </div>
  </div>

  <div class="ConfirmPhoto-buttons">
    <button mat-raised-button color="warn" class="" (click)="cancelPhoto()">
      Cancelar
    </button>

    <button mat-raised-button color="primary" class="" (click)="sendPhoto()">
      Enviar foto
    </button>

  </div>
</div>
