import {EMPTY} from 'rxjs';
import {MyGlobalsService} from '../my-globals/my-globals.service';
import {EPageLoaderStatus} from '../my-globals/definitions';
import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {IGenericHttpError} from '../../definitions';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MyHttpUtils {
  constructor(
    private snackBar: MatSnackBar,
    private myGlobals: MyGlobalsService
  ) {
  }

  public breakObservableChain() {
    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

    return EMPTY;
  }

  /**
   * Basic header of every authenticated request
   */
  public configBasicAuthHeader() : HttpHeaders {
    return new HttpHeaders({
      'authorization': `bearer ${this.myGlobals.userToken || JSON.parse(localStorage.getItem('userData')).userToken}`
    });
  }
}
