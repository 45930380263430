<div class="InfoFotoDocs">

  <app-page-title>Precisamos de algumas fotos</app-page-title>

  <div class="InfoFotoDocs-card InfoFotoDocs-card--photo">
    <button class=" InfoFotoDocs-button--bigger " mat-raised-button color="accent" [ngClass]="{ok: !!photo }"
            (click)="uploadPersonPhoto();">

      <span class="InfoFotoDocs-buttonWrapper">
        <i class="icofont-user-alt-3"></i>
        <span>Foto (Face)</span>
      </span>

<!--      <i class="InfoFotoDocs-buttonIcon&#45;&#45;Uploaded icofont-check-circled"></i>-->
      <i class="InfoFotoDocs-buttonIcon--Uploaded icofont-check" *ngIf="photo"></i>

    </button>
  </div>

  <div class="InfoFotoDocs-divider">
    <mat-divider></mat-divider>
  </div>

  <div class="InfoFotoDocs-card">

    <div class="InfoFotoDocs-buttons--rg">
      <button mat-raised-button color="accent" [ngClass]="{ok: !!rg[0] }"
              (click)="uploadDocumentPhoto('rgF')">

          <span class="InfoFotoDocs-buttonWrapper">
            <i class="icofont-id-card"></i>
             <span>RG Frente (foto)</span>
          </span>

        <i class="InfoFotoDocs-buttonIcon--Uploaded icofont-check" *ngIf="rg[rgFrontIndex]"></i>
      </button>

      <button mat-raised-button color="accent" [ngClass]="{ok: !!rg[1] }"
              (click)="uploadDocumentPhoto('rgB')">

             <span class="InfoFotoDocs-buttonWrapper">
                <i class="icofont-id-card"></i>
                <span>RG Verso (texto)</span>
             </span>

        <i class="InfoFotoDocs-buttonIcon--Uploaded icofont-check" *ngIf="rg[rgBackIndex]"></i>
      </button>
    </div>

    <div class="InfoFotoDocs-divider">
      <mat-divider class="InfoFotoDocs-divider--small"></mat-divider>
      <p>ou</p>
    </div>

    <div class="InfoFotoDocs-buttons--cnh">
      <button mat-raised-button color="accent" [ngClass]="{ok: !!cnh }"
              (click)="uploadDocumentPhoto('cnh')">

        <span class="InfoFotoDocs-buttonWrapper">
          <i class="icofont-card"></i>
          <span>CNH</span>
        </span>

        <i class="InfoFotoDocs-buttonIcon--Uploaded icofont-check" *ngIf="cnh"></i>
      </button>
    </div>

  </div>

  <div class="InfoFotoDocs-buttonWrapper--send">
    <button type="button" class="InfoFotoDocs-button--erase" mat-raised-button color="warn" (click)="eraseAllPhotos()">Apagar os documentos</button>
    <button class="InfoFotoDocs-button--send" mat-raised-button color="accent" (click)="sendProposal()">Enviar</button>
  </div>

</div>
