import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {IServerError} from './definitions';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorUtilsService {

  private readonly displayTime = 5000;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  public genericError() {
    this.snackBar.open('Houve um erro no servidor.', 'ok', {
      duration: this.displayTime,
      panelClass: ['MySnackBar', 'MySnackBar--error'],
    });
  }

  public showHttpError(error: IServerError) {
    if(!error || !error['message']) {
      this.genericError();
      return;
    }
    if (error?.message?.cod) {
      this.snackBar.open(`${error?.message?.cod}: ${error?.message?.msg}`, 'ok', {
        duration: this.displayTime,
        panelClass: ['MySnackBar', 'MySnackBar--error']
      });
      return;
    }
    this.snackBar.open(`${error?.message}`, 'ok', {
      duration: this.displayTime,
      panelClass: ['MySnackBar', 'MySnackBar--error']
    });
  }

  public showForbiddenError(redirect = true) {
    this.snackBar.open('Faça o login novamente', 'ok', {
      duration: this.displayTime,
      panelClass: ['MySnackBar', 'MySnackBar--error'],
    });

    if(redirect) {
      this.router.navigate(['/emprestimo/estados-permitidos'], {});
    }
  }

}
