import {FormGroup, ValidationErrors} from '@angular/forms';

export function dateValidator(control: FormGroup) : ValidationErrors | null {
  let val = control.value.split('.');

  if(val.length != 3) {
    return {date: true};
  }

  if(val[0].length != 2) {
    return {date: true};
  }

  if(val[1].length != 2) {
    return {date: true};
  }

  if(val[2].length != 4) {
    return {date: true};
  }
  return null;
}
