import {IInstallmentDetails} from "../info-emprestimo/definitions";

export interface IGetQuestionsHttpResponse {
  contentResponse: {
    docInfo: {};
    questionnaireId: string;
    resultCode: number;
    resultMessage: string;
    questions: IQuestionInfo[];
    status: EQuestionAnswerStatus;
  },
  success: boolean;
}

export interface IQuestionInfo {
  question: string;
  answers: string[];
}

export interface ISendAnswersHttpResponse {
  success: boolean;
  contentResponse: {
    docInfo: {};
    ticketId: string;
    resultCode: number;
    resultMessage: string;
    questions: IQuestionInfo[];
    status: EQuestionAnswerStatus;
  }
}

export enum EQuestionAnswerStatus {
  None,
  Approved,
  Denied,
  Error
}
