<div class="Perfil">
  <app-page-title>Suas informações</app-page-title>

  <div [ngClass]="{'Perfil-form--hide':isPreProposal}">
    <form class="Perfil-form" [formGroup]="mainForm" (submit)="savePerson()" novalidate>

      <div class="Perfil-accordionWrapper">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Pessoal
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field class="fullWidth">
              <input
                matInput
                placeholder="CPF"
                formControlName="cpf"
                type="text"
                [imask]="cpfMask"
              >

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                type="email"
                class="bigFont"
                matInput
                placeholder="E-mail"
                name="email"
                formControlName="email"
                [imask]="emailMask"
              >

            </mat-form-field>

            <mat-form-field class="fullWidth">
              <input
                matInput
                placeholder="Nome Completo"
                formControlName="nome"
                type="text"
              >
              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('nome');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="fullWidth">
              <input
                matInput
                type="tel"
                placeholder="Data de Nascimento"
                name="birthDate"
                formControlName="dataNascimento"
                [imask]="birthDateMask"
              >
              <mat-error *ngIf="mainForm.get('dataNascimento').hasError('date')">
                Data inválida
              </mat-error>

              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('dataNascimento');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <mat-select
                class="bigFont"
                placeholder="Sexo"
                formControlName="sexo"
              >
                <mat-option value="F">
                  Feminino
                </mat-option>

                <mat-option value="M">
                  Masculino
                </mat-option>

              </mat-select>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                [imask]="telMask"
                type="tel"
                placeholder="Celular"
                name="cellphone"
                formControlName="telefoneCelular"
              >

              <mat-error *ngIf="mainForm.get('telefoneCelular').hasError('cellphone')">
                Celular inválido
              </mat-error>
              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('telefoneCelular');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                [imask]="telMask"
                type="tel"
                placeholder="Telefone Residencial"
                name="cellphone"
                formControlName="telefoneResidencial"
              >

              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('telefoneResidencial');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                [imask]="rgMask"
                type="tel"
                placeholder="RG (identidade)"
                name="rg"
                formControlName="rg"
              >
              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('rg');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                type="text"
                placeholder="Órgão Emissor (RG)"
                name="rgEmitter"
                formControlName="rgOrgaoEmissor"
              >
              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('rgOrgaoEmissor');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <mat-select
                class="bigFont"
                placeholder="UF (RG)"
                formControlName="rgUf"
              >

                <mat-option *ngFor="let state of brazilStates" [value]="state.abbr">
                  {{state.state}}
                </mat-option>

              </mat-select>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                placeholder="Nome da Mãe"
                name="motherName"
                formControlName="nomeMae"
              >
              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('nomeMae');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                placeholder="Nome do Pai"
                name="fatherName"
                formControlName="nomePai"
              >

              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('nomePai');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <mat-select
                class="bigFont"
                placeholder="Estado Civil"
                formControlName="estadoCivil"
              >

                <mat-option *ngFor="let state of civilState" [value]="state.value">
                  {{state.text}}
                </mat-option>

              </mat-select>

            </mat-form-field>

            <mat-form-field class="fullWidth">
              <input
                matInput
                placeholder="Nome de uma Referência"
                formControlName="referenciaNome"
                type="text"
              >

              <mat-error *ngIf="mainForm.get('referenciaNome').hasError('required')">
                Campo obrigatório
              </mat-error>

              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('referenciaNome');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                [imask]="telMask"
                type="tel"
                placeholder="Telefone de uma Referência"
                name="referenciaTelefone"
                formControlName="referenciaTelefone"
              >

              <mat-error *ngIf="mainForm.get('referenciaTelefone').hasError('required')">
                Campo obrigatório
              </mat-error>

              <mat-error *ngIf="mainForm.get('referenciaTelefone').hasError('telephone')">
                Telefone inválido
              </mat-error>

              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('referenciaTelefone');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>

            </mat-form-field>

          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Endereço
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                [imask]="cepMask"
                type="tel"
                placeholder="CEP Residencial"
                name="cep"
                formControlName="cep"
                (keyup)="onClickSearchCepPersonal()"
              >

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                placeholder="Endereço"
                name="address1"
                formControlName="endereco"
              >
              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('endereco');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                placeholder="Complemento"
                name="address2"
                formControlName="complemento"
              >
              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('complemento');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                placeholder="Número"
                name="number"
                formControlName="numero"
              >
              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('numero');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                placeholder="Bairro"
                name="neighborhood"
                formControlName="bairro"
              >
              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('bairro');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                placeholder="Cidade"
                name="city"
                formControlName="cidade"
              >
              <button type="button" mat-button matSuffix mat-icon-button aria-label="Limpar"
                      (click)="onClickClearField('cidade');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <mat-select class="bigFont" placeholder="Estado" formControlName="estado">

                <mat-option *ngFor="let state of brazilStates" [value]="state.abbr">{{state.state}}</mat-option>

              </mat-select>

            </mat-form-field>

          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Profissional
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                placeholder="Cargo/Função"
                name="workName"
                formControlName="cargoFuncaoTrabalho"
              >
              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('cargoFuncaoTrabalho');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                type="tel"
                placeholder="Renda Mensal"
                name="monthlyIncome"
                formControlName="renda"
              />

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                matInput
                [imask]="workAdmissionMask"
                type="tel"
                placeholder="Data de Admisão"
                name="admissionDate"
                formControlName="dataAdmissaoTrabalho"
              >

              <mat-error *ngIf="mainForm.get('dataAdmissaoTrabalho').hasError('date')">
                Data inválida
              </mat-error>

              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('dataAdmissaoTrabalho');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <mat-select class="bigFont" placeholder="Situação de trabalho" formControlName="situacaoTrabalho">

                <mat-option *ngFor="let item of workState" [value]="item.value">
                  {{item.text}}
                </mat-option>

              </mat-select>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                placeholder="Nome da Empresa"
                name="empresaNome"
                formControlName="empresaNome"
              >

              <mat-error *ngIf="mainForm.get('empresaNome').hasError('required')">
                Campo obrigatório
              </mat-error>

              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('empresaNome');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                [imask]="telMask"
                type="tel"
                placeholder="Telefone da Empresa"
                name="empresaTelefone"
                formControlName="empresaTelefone"
              >

              <mat-error *ngIf="mainForm.get('empresaTelefone').hasError('required')">
                Campo obrigatório
              </mat-error>

              <mat-error *ngIf="mainForm.get('empresaTelefone').hasError('telephone')">
                Telefone inválido
              </mat-error>

              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('empresaTelefone');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                [imask]="cepMask"
                type="tel"
                placeholder="CEP da Empresa"
                name="empresaCep"
                formControlName="empresaCep"
                (keyup)="onClickSearchCepWork()"
              >
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                placeholder="Endereço da Empresa"
                name="empresaEndereco"
                formControlName="empresaEndereco"
              >

              <mat-error *ngIf="mainForm.get('empresaEndereco').hasError('required')">
                Campo obrigatório
              </mat-error>

              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('empresaEndereco');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                placeholder="Número do Endereço da Empresa"
                name="empresaEnderecoNumero"
                formControlName="empresaEnderecoNumero"
              >

              <mat-error *ngIf="mainForm.get('empresaEnderecoNumero').hasError('required')">
                Campo obrigatório
              </mat-error>

              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('empresaEnderecoNumero');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                placeholder="Bairro da Empresa"
                name="empresaBairro"
                formControlName="empresaBairro"
              >

              <mat-error *ngIf="mainForm.get('empresaBairro').hasError('required')">
                Campo obrigatório
              </mat-error>

              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('empresaBairro');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                placeholder="Cidade da Empresa"
                name="empresaCidade"
                formControlName="empresaCidade"
              >

              <mat-error *ngIf="mainForm.get('empresaCidade').hasError('required')">
                Campo obrigatório
              </mat-error>

              <button type="button" mat-button matSuffix mat-icon-button
                      aria-label="Limpar" (click)="onClickClearField('empresaCidade');">
                <mat-icon class="Button-clearInput">
                  <i class="icofont-close"></i>
                </mat-icon>
              </button>

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <mat-select class="bigFont" placeholder="Estado da Empresa" formControlName="empresaUf">

                <mat-option *ngFor="let state of brazilStates" [value]="state.abbr">{{state.state}}</mat-option>

              </mat-select>

              <mat-error *ngIf="mainForm.get('empresaUf').hasError('required')">
                Campo obrigatório
              </mat-error>

            </mat-form-field>

          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Dados Bancários
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field class="fullWidth">

              <mat-select class="bigFont" placeholder="Tipo de conta bancária" formControlName="tipoContaPagto">
                <mat-option *ngFor="let opt of howReceivePayment" [value]="opt.value">
                  {{opt.text}}
                </mat-option>
              </mat-select>

            </mat-form-field>

            <mat-form-field class="fullWidth">

              <input type="text" placeholder="Bancos" aria-label="Number" matInput formControlName="bancoPagtoName" [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let opt of bankInfoDataFiltered | async" [value]="opt.nome" >
                  {{opt.nome}}
                </mat-option>
              </mat-autocomplete>

            </mat-form-field>

            <div class="Perfil-bankNumberWithDv">
              <mat-form-field class="agency " [hideRequiredMarker]="true">
                <input
                  class="bigFont"
                  matInput
                  placeholder="Agência"
                  name="agency"
                  formControlName="agenciaPagto"
                  maxlength="5"
                >
              </mat-form-field>

              <mat-form-field class=" smallInput " [hideRequiredMarker]="true">
                <input
                  class="bigFont "
                  matInput
                  placeholder="Dígito"
                  name="agencyDv"
                  formControlName="digitoAgenciaPagto"
                  maxlength="2"
                >
              </mat-form-field>
            </div>

            <div class="Perfil-bankNumberWithDv">
              <mat-form-field class="account " [hideRequiredMarker]="true">
                <input
                  class="bigFont"
                  matInput
                  placeholder="Conta"
                  name="account"
                  formControlName="contaPagto"
                  maxlength="12"
                >
              </mat-form-field>

              <mat-form-field class=" smallInput " [hideRequiredMarker]="true">
                <input
                  class="bigFont"
                  matInput
                  placeholder="Dígito"
                  name="accountDv"
                  formControlName="digitoContaPagto"
                  maxlength="2"
                >
              </mat-form-field>
            </div>

          </mat-expansion-panel>

        </mat-accordion>
      </div>

      <div class="Perfil-buttons">
        <button mat-raised-button color="accent">Salvar</button>
      </div>

    </form>
  </div>

  <div [ngClass]="{'Perfil-form--hide':!isPreProposal}">
    <form class="Perfil-form" [formGroup]="preProposalForm" (submit)="preProposalSaveInfo()">
      <div class="Perfil-accordionWrapper">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Cadastro
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field class="fullWidth">
              <input
                matInput
                placeholder="CPF"
                formControlName="cpf2"
                type="text"
                [imask]="cpfMask"
              >

            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                type="text"
                placeholder="Nome"
                name="fullName"
                formControlName="fullName2"
              />
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                type="tel"
                placeholder="Data de Nascimento"
                name="bithDate"
                formControlName="bithDate2"
                [imask]="birthDateMask"
              />
            </mat-form-field>

            <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
              <input
                class="bigFont"
                matInput
                type="tel"
                placeholder="Celular"
                name="phone"
                formControlName="phone2"
                [imask]="telMask"
              />
            </mat-form-field>

          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div class="Perfil-buttons">
        <button mat-raised-button color="accent">Salvar</button>
      </div>
    </form>
  </div>

  <form class="Perfil-form" [formGroup]="accountForm" (submit)="changePassword()" novalidate>
    <div class="Perfil-accordionWrapper">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Informações de autenticação
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
            <input
              class="bigFont"
              matInput
              type="email"
              placeholder="Usuário"
              name="username"
              formControlName="username"
            />
          </mat-form-field>

          <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
            <input
              class="bigFont"
              matInput
              type="password"
              placeholder="Senha Atual"
              name="oldPass"
              formControlName="oldPass"
            />
          </mat-form-field>

          <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
            <input
              class="bigFont"
              matInput
              type="password"
              placeholder="Nova Senha"
              name="newPass"
              formControlName="newPass"
            />
          </mat-form-field>

          <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
            <input
              class="bigFont"
              matInput
              type="password"
              placeholder="Nova Senha Confirmação"
              name="newPassAgain"
              formControlName="newPassAgain"
            />
          </mat-form-field>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="Perfil-buttons">
      <button type="button" mat-raised-button color="warn" (click)="deleteAccount()">Apagar conta!</button>
      <button mat-raised-button color="accent">Salvar</button>
    </div>

  </form>

</div>
