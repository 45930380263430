import { Component, OnInit } from '@angular/core';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {MyHttpUtils} from '../../../services/utils/HttpUtils';
import {ProposalService} from '../proposal.service';

@Component({
  selector: 'app-termos-uso',
  templateUrl: './termos-uso.component.html',
  styleUrls: ['./termos-uso.component.scss']
})
export class TermosUsoComponent implements OnInit {

  public useTerms = '';
  public mainForm: FormGroup;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private myHttpUtils: MyHttpUtils,
    private myGlobals: MyGlobalsService,
    private proposalData: ProposalService
  ) {
    this.useTerms = myGlobals.useTerms;

  }

  ngOnInit() {
    this.initForm();
  }

  nextPage() {
    if(!this.mainForm.valid) {
      this.snackBar.open('Para proceguir é preciso aceitar os termos', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    this.proposalData.setUseTermsData(this.mainForm.value, this.useTerms);

    this.router.navigate(['emprestimo/info-vendedor'], {});

  }

  private initForm() {
    this.mainForm = this.formBuilder.group({
      aceito: ['', [
        Validators.required
      ]]
    })
  }
}
