import { Injectable } from '@angular/core';
import {ECameraFacingMode, ICameraContraints, ICameraOptions} from "./definitions";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  public static defaultConstraints: ICameraContraints = {
    video: {
      frameRate: 20,
      width: 1280,
      height: 720,
      facingMode: ECameraFacingMode.Environment,
      aspectRatio: 1.7777777778
    }
  };

  private constraints: ICameraContraints;
  private videoElem: HTMLVideoElement;
  private canvasElem: HTMLCanvasElement;
  private stream: MediaStream;

  constructor(
    private snackBar: MatSnackBar,
  ) {
  }

  init(opts: ICameraOptions): void {

    this.videoElem = opts.videoElem;
    this.canvasElem = opts.canvasElem;
    this.constraints = opts.constraints;

    let arrCameras: MediaDeviceInfo[] = [];

    navigator.mediaDevices.enumerateDevices()
      .then((mediaDeviceInfo: MediaDeviceInfo[]) => {

        mediaDeviceInfo.forEach((mediaDevice: MediaDeviceInfo) => {

          if (mediaDevice.kind === 'videoinput') {
            arrCameras.push(mediaDevice);
          }

        });

        arrCameras = arrCameras.sort((a, b) => a.label.localeCompare(b.label));

        return navigator.mediaDevices.getUserMedia({
          video: {
            ...opts.constraints.video,
            deviceId: arrCameras[0].deviceId
          },
        });
      })
      .then((stream: MediaStream) => {
        this.stream = stream;
        this.play();
      })
      .catch(error => {
        this.snackBar.open('Houve um erro ao tentar acessar a câmera: ' + error, 'ok', {
          panelClass: ['MySnackBar', 'MySnackBar--error'],
        });
      });
  }

  play(): void {
    this.videoElem.srcObject = this.stream;
    this.videoElem.onloadedmetadata = (e) => {
      this.videoElem.play();
    };
  }

  stop(): void {
    if (!this.stream) {
      return;
    }

    this.stream.getTracks().forEach(function (track) {
      track.stop();
    });
  }

  takeImage(): string {

    this.canvasElem.width = this.videoElem.videoWidth;
    this.canvasElem.height = this.videoElem.videoHeight;

    this.canvasElem
      .getContext('2d')
      .drawImage(this.videoElem, 0, 0, this.videoElem.videoWidth, this.videoElem.videoHeight);

    return this.canvasElem.toDataURL('image/jpeg', 90);
  }
}
