import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-doc-photo-block',
  templateUrl: './doc-photo-block.component.html',
  styleUrls: ['./doc-photo-block.component.scss']
})
export class DocPhotoBlockComponent implements OnInit {

  @Input() name: string;
  @Input() photo: string; // base64
  @Input() showDelete = true;
  @Output() fullScreen: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClickFullScreen() {
    this.fullScreen.emit();
  }

  onClickDelete() {
    this.delete.emit();
  }

}
