<div class="InfoTrabalho">
  <app-page-title>Qual é a sua profissão?</app-page-title>

  <form [formGroup]="mainForm" (submit)="nextPage();" novalidate>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        placeholder="Cargo/Função"
        name="workName"
        formControlName="cargoFuncaoTrabalho"
      >

      <mat-error *ngIf="mainForm.get('cargoFuncaoTrabalho').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        currencyMask
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left', nullable:false }"
        type="tel"
        placeholder="Renda Mensal"
        name="monthlyIncome"
        formControlName="renda"
      />

      <mat-error *ngIf="mainForm.get('renda').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        [imask]="dateMask"
        type="tel"
        placeholder="Data de Admisão"
        name="admissionDate"
        formControlName="dataAdmissaoTrabalho"
      >

      <mat-error *ngIf="mainForm.get('dataAdmissaoTrabalho').hasError('date')">
        Data inválida
      </mat-error>

      <mat-error *ngIf="mainForm.get('dataAdmissaoTrabalho').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <mat-select class="bigFont" placeholder="Situação de trabalho" formControlName="situacaoTrabalho">

        <mat-option *ngFor="let item of workState" [value]="item.value">
          {{item.text}}
        </mat-option>

      </mat-select>

      <mat-error *ngIf="mainForm.get('situacaoTrabalho').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        placeholder="Nome da Empresa"
        name="empresaNome"
        formControlName="empresaNome"
      >

      <mat-error *ngIf="mainForm.get('empresaNome').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        [imask]="telMask"
        type="tel"
        placeholder="Telefone da Empresa"
        name="empresaTelefone"
        formControlName="empresaTelefone"
      >

      <mat-error *ngIf="mainForm.get('empresaTelefone').hasError('required')">
        Campo obrigatório
      </mat-error>

      <mat-error *ngIf="mainForm.get('empresaTelefone').hasError('telephone')">
        Telefone inválido
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        [imask]="cepMask"
        type="tel"
        placeholder="CEP da Empresa"
        name="empresaCep"
        formControlName="empresaCep"
        (keyup)="onClickSearchCep()"
      >
    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        placeholder="Endereço da Empresa"
        name="empresaEndereco"
        formControlName="empresaEndereco"
      >
      <button type="button" mat-button *ngIf="showClearFieldBtn('empresaEndereco')" matSuffix mat-icon-button aria-label="Limpar" (click)="onClickClearField('empresaEndereco');">
        <mat-icon class="Button-clearInput">
          <i class="icofont-close"></i>
        </mat-icon>
      </button>

      <mat-error *ngIf="mainForm.get('empresaEndereco').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        placeholder="Número do Endereço da Empresa"
        name="empresaEnderecoNumero"
        formControlName="empresaEnderecoNumero"
      >
      <button type="button" mat-button *ngIf="showClearFieldBtn('empresaEnderecoNumero')" matSuffix mat-icon-button aria-label="Limpar" (click)="onClickClearField('empresaEnderecoNumero');">
        <mat-icon class="Button-clearInput">
          <i class="icofont-close"></i>
        </mat-icon>
      </button>

      <mat-error *ngIf="mainForm.get('empresaEnderecoNumero').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        placeholder="Bairro da Empresa"
        name="empresaBairro"
        formControlName="empresaBairro"
      >
      <button type="button" mat-button *ngIf="showClearFieldBtn('empresaBairro')" matSuffix mat-icon-button aria-label="Limpar" (click)="onClickClearField('empresaBairro');">
        <mat-icon class="Button-clearInput">
          <i class="icofont-close"></i>
        </mat-icon>
      </button>

      <mat-error *ngIf="mainForm.get('empresaBairro').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        placeholder="Cidade da Empresa"
        name="empresaCidade"
        formControlName="empresaCidade"
      >
      <button type="button" mat-button *ngIf="showClearFieldBtn('empresaCidade')" matSuffix mat-icon-button aria-label="Limpar" (click)="onClickClearField('empresaCidade');">
        <mat-icon class="Button-clearInput">
          <i class="icofont-close"></i>
        </mat-icon>
      </button>

      <mat-error *ngIf="mainForm.get('empresaCidade').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <mat-select class="bigFont" placeholder="Estado da Empresa" formControlName="empresaUf">

        <mat-option *ngFor="let state of brazilStates" [value]="state.abbr">{{state.state}}</mat-option>

      </mat-select>

      <mat-error *ngIf="mainForm.get('empresaUf').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <div class="flex-end">
      <button mat-raised-button color="accent">Continuar</button>
    </div>

  </form>

</div>
