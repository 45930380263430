import {AfterViewChecked, Component, ElementRef, Inject, NgZone, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import {CameraService} from "../../../services/camera/camera.service";
import {ECameraFacingMode, ECameraOrientation, ICameraOptions} from "../../../services/camera/definitions";
import {PhotoModalParams2} from "./definitions";

@Component({
  selector: 'app-take-photo-dialog',
  templateUrl: './take-photo-dialog.component.html',
  styleUrls: ['./take-photo-dialog.component.scss']
})
export class TakePhotoDialogComponent implements AfterViewChecked {

  @ViewChild('cameraVideo') cameraVideo: ElementRef<HTMLVideoElement>;
  @ViewChild('cameraProcessing') cameraProcessing: ElementRef<HTMLCanvasElement>;
  @ViewChild('dialogMainDiv') dialogMainDiv: ElementRef<HTMLDivElement>;

  public modalParams: PhotoModalParams2;
  public cameraImg;
  public sliderPosition = 0;
  public photoError = '';
  public isLandscape = false;

  private orie: ECameraOrientation;
  private width: number;
  private height: number;

  private isToInitCamera = true;
  private facingMode: ECameraFacingMode = ECameraFacingMode.Environment;

  constructor(
    private photoDialogRef: MatDialogRef<TakePhotoDialogComponent>,
    private theZone: NgZone,
    private myGlobals: MyGlobalsService,
    private http: HttpClient,
    private camera: CameraService,
    @Inject(MAT_DIALOG_DATA) public data: PhotoModalParams2
  ) {
    this.modalParams = data;
  }

  ngAfterViewChecked() {
    if (this.isToInitCamera) {
      this.isToInitCamera = false;

      if (this.modalParams.camera) {
        this.orie =
          this.modalParams.camera.orientation ? this.modalParams.camera.orientation : ECameraOrientation.Portrait;
        this.width = 720;
        this.height = 1280;
        this.isLandscape = this.modalParams.camera.hasChangeOrientation ? this.modalParams.camera.hasChangeOrientation : false;

        this.initCamera(this.orie, this.width, this.height);
      } else {
        this.initCamera();
      }

    }
  }

  initCamera(orientation: ECameraOrientation = ECameraOrientation.Portrait, width = 1280, height = 960,
             facingMode: ECameraFacingMode = ECameraFacingMode.Environment) {

    if (window.screen.orientation.type == "landscape-primary") {
      const temp = height;
      height = width;
      width = temp;
    }

    const cameraOptions: ICameraOptions = {
      canvasElem: this.cameraProcessing.nativeElement,
      videoElem: this.cameraVideo.nativeElement,
      showFrame: this.modalParams.isPersonPhoto,
      constraints: {
        video: {
          height: {ideal: height},
          facingMode: facingMode,
        }
      },
    };

    if (orientation == ECameraOrientation.Landscape) {
      if (this.dialogMainDiv.nativeElement.requestFullscreen)
        this.dialogMainDiv.nativeElement.requestFullscreen()
          .then(() => {
            screen.orientation.lock(ECameraOrientation.Landscape)
              .then(() => {
                this.camera.init(cameraOptions);
              })
              .catch((error) => {
                this.camera.init(cameraOptions);
              });
          });

    } else {
      this.camera.init(cameraOptions);
    }
  }

  takePhoto() {
    this.takenPhoto();
  }

  takenPhoto() {

    this.cameraImg = this.camera.takeImage();
    this.camera.stop();
    this.nextSlide();
  }

  nextSlide() {
    if (this.isLandscape) {
      screen.orientation.unlock();

      setTimeout(() => {
        this.photoDialogRef.updateSize(window.innerWidth + 'px', '100vh');
      }, 0);
    }

    this.sliderPosition = 1;
  }

  prevSlide() {

    if (this.isLandscape) {
      screen.orientation.lock('landscape')
        .then(() => {
          this.photoDialogRef.updateSize();

          this.sliderPosition = 0;
          this.isToInitCamera = true;
        })
        .catch(() => {
        });
    } else {
      this.sliderPosition = 0;
      this.isToInitCamera = true;
    }

  }

  cancelPhotoSend() {
    this.photoError = '';
    this.prevSlide();
  }

  onSendPhoto() {
    if (!this.modalParams.isPersonPhoto) {

      this.photoDialogRef.close(this.cameraImg);
      return;
    }

    this.sendPhoto();
  }

  sendPhoto() {
    if (!this.modalParams.isPersonPhoto) {

      this.photoDialogRef.close(this.cameraImg);
      return;
    }

    this.photoDialogRef.close(this.cameraImg);
  }

  closeModal() {
    this.camera.stop();
    this.photoDialogRef.close(null);
  }

  changeCamera() {
    this.camera.stop();

    if (this.facingMode == ECameraFacingMode.Environment) {
      this.facingMode = ECameraFacingMode.User;
    } else {
      this.facingMode = ECameraFacingMode.Environment;
    }

    this.initCamera(this.orie, this.width, this.height, this.facingMode);
  }
}
