<div class="InfoContato">
  <app-page-title>Como informar o resultado do seu pedido?</app-page-title>

  <form [formGroup]="mainForm" (submit)="nextPage();" novalidate>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        [imask]="telMask"
        type="tel"
        placeholder="Celular"
        name="cellphone"
        formControlName="telefoneCelular"
      >

      <mat-error *ngIf="mainForm.get('telefoneCelular').hasError('required')">
        Campo obrigatório
      </mat-error>

      <mat-error *ngIf="mainForm.get('telefoneCelular').hasError('cellphone')">
        Celular inválido
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        [imask]="telMask"
        type="tel"
        placeholder="Telefone residencial"
        name="telRes"
        formControlName="telefoneResidencial"
      >

      <mat-error *ngIf="mainForm.get('telefoneResidencial').hasError('required')">
        Campo obrigatório
      </mat-error>

      <mat-error *ngIf="mainForm.get('telefoneResidencial').hasError('telephone')">
        Telefone inválido
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        type="email"
        class="bigFont"
        matInput
        placeholder="E-mail"
        name="email"
        formControlName="email"
        [imask]="emailMask"
      >

      <mat-error *ngIf="mainForm.get('email').hasError('required')">
        Campo obrigatório
      </mat-error>

      <mat-error *ngIf="mainForm.get('email').hasError('email')">
        E-mail inválido
      </mat-error>

    </mat-form-field>

    <div class="flex-end">
      <button mat-raised-button color="accent">Continuar</button>
    </div>

  </form>

</div>
