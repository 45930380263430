import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TakePhotoDialogComponent} from './take-photo-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import {ConfirmPhoto2Module} from "./confirm-photo2/confirm-photo2.module";

@NgModule({
  declarations: [TakePhotoDialogComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        ConfirmPhoto2Module,
    ],
  exports: [TakePhotoDialogComponent]
})
export class TakePhotoDialogModule {
}
