import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionnaireComponent } from './questionnaire.component';
import {PageTitleModule} from "../../../components/page-title/page-title.module";
import {MatButtonModule} from "@angular/material/button";
import {ReactiveFormsModule} from "@angular/forms";
import {MatRadioModule} from "@angular/material/radio";
import {MatIconModule} from "@angular/material/icon";



@NgModule({
  declarations: [
    QuestionnaireComponent
  ],
    imports: [
        CommonModule,
        PageTitleModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatIconModule
    ]
})
export class QuestionnaireModule { }
