import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {delay} from 'rxjs/operators';
import {EMainMenuStatus} from '../../services/my-globals/definitions';
import {MyGlobalsService} from '../../services/my-globals/my-globals.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  public isOpen: boolean;

  public events: string[] = [];

  constructor(
    private myGlobals: MyGlobalsService
  ) { }

  public ngOnInit() {
    this.myGlobals.getGlobalMainMenuStatus().pipe(delay(0)).subscribe((value:EMainMenuStatus) => {
      this.isOpen = value === EMainMenuStatus.visible;
    })
  }

  public close() {
    this.sidenav.close();
  }

}
