import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MyHttpUtils} from '../../../services/utils/HttpUtils';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import * as moment from 'moment';
import {catchError} from 'rxjs/operators';
import {IHttpResponseInstallment, IInstallmentDetails} from './definitions';
import {EPageLoaderStatus} from '../../../services/my-globals/definitions';
import {ProposalService} from '../proposal.service';
import {ILoanInfo, ILoanInfoFormValues} from '../definitions';
import {Moment} from 'moment';
import {FormUtilsService} from '../../../services/utils/form-utils.service';
import {IServerError} from '../../../services/utils/error/definitions';
import {ErrorUtilsService} from '../../../services/utils/error/error-utils.service';

@Component({
  selector: 'app-info-emprestimo',
  templateUrl: './info-emprestimo.component.html',
  styleUrls: ['./info-emprestimo.component.scss']
})
export class InfoEmprestimoComponent implements OnInit {

  public minDate = moment().add(30, 'd');
  public maxDate = moment().add(45, 'd');
  public mainForm: FormGroup;
  public dateMask = {};
  public maxLoan = 0;
  public installments: Array<IInstallmentDetails> = [];

  private lastTimeoutUpdateInstall = -1;
  public blockContinueButton = true;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private myHttpUtils: MyHttpUtils,
    private proposalData: ProposalService,
    private myErrorUtils: ErrorUtilsService,
    private myGlobals: MyGlobalsService
  ) {
    this.maxLoan = MyGlobalsService.maxLoan;
  }

  ngOnInit() {
    this.initForm();
  }

  nextPage() {
    if (!this.mainForm.valid || this.installments.length === 0) {
      this.snackBar.open('Faltou preencher os campos obrigatórios', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    const loanInfoFormValues: ILoanInfoFormValues = this.mainForm.value;

    const loadDataSaved:ILoanInfo = this.proposalData.loanData;

    const loanInfo: ILoanInfo = {
      pessoa_id: this.myGlobals.personInfo ? this.myGlobals.personInfo.id : -1,
      plano: loanInfoFormValues.prazo.plano,
      prazo: loanInfoFormValues.prazo.prazo,
      vencimento_inicial: (<Moment> <unknown> loanInfoFormValues.vencimento_inicial).format('Y-MM-DD'),
      valor: loanInfoFormValues.prazo.valorEmprestimo,
      financiado: loanInfoFormValues.prazo.valorEmprestimo,
      entrada: 0,
      tac: loanInfoFormValues.prazo.tac,
      iof: loanInfoFormValues.prazo.iof,
      prestacao: loanInfoFormValues.prazo.valorParcela,
      aceito: true,
      termosDeUso: this.myGlobals.useTerms,
      agente_vendas_id: loadDataSaved.agente_vendas_id
    }

    this.proposalData.setLoanInfo(loanInfo);

    this.router.navigate(['emprestimo/info-pessoal'], {});
  }

  private initForm() {

    this.dateMask = {
      mask: Date,
      min: this.minDate,
      max: this.maxDate,
      pattern: 'd.m.Y',
    };

    this.mainForm = this.formBuilder.group({
      financiado: ['0', [
        Validators.required,
        Validators.max(MyGlobalsService.maxLoan)
      ]],
      vencimento_inicial: [{
        value: ''
      }, [
        Validators.required
      ]],
      prazo: [{
        value: '',
        disabled: true
      }, [
        Validators.required
      ]]
    });
  }

  public onDateChange() {
    this.updateInstallments();
  }

  private updateInstallments() {

    clearTimeout(this.lastTimeoutUpdateInstall);

    this.lastTimeoutUpdateInstall = setTimeout(() => {
      if (!this.mainForm.get('vencimento_inicial').valid || parseInt(this.mainForm.get('financiado').value) == 0 || !this.mainForm.get('financiado').valid) {
        return;
      }

      this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

      this.http.post(`${this.myGlobals.baseUrlApi}/api/ucredit/padraosistemas/simulatecredit`, {
        Loja: 0,
        Plano: 0,
        Prazo: 0,
        ValorDaParcela: 0,
        ValorDoEmprestimo: this.mainForm.get('financiado').value,
        DataSimulacao: '2020-01-01',
        DataVencimento: this.mainForm.get('vencimento_inicial').value.format('YYYY-MM-DD'),
        OpcaoDeCalculo: 'ValordoEmprestimo'
      }, {
        headers: this.myHttpUtils.configBasicAuthHeader()
      })
        .pipe(
          catchError((err: HttpErrorResponse) => {
            this.myErrorUtils.showHttpError(err.error);

            return this.myHttpUtils.breakObservableChain();
          })
        )
        .subscribe((data: IHttpResponseInstallment) => {
          this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

          this.mainForm.get('prazo').enable();
          this.installments = data.contentResponse.registros;

          this.blockContinueButton = false;
        });
    }, 1300);

  }

  onLoanChange() {
    this.updateInstallments();
  }

  prettifyValorParcela(ValorParcela: number) {
    const numberFormatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });

    return numberFormatter.format(ValorParcela);
  }

  cancelKeypress() {
    return false;
  }
}
