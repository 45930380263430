import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FinalStepComponent} from './final-step.component';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [FinalStepComponent],
    imports: [
        CommonModule,
        MatButtonModule
    ],
  exports: [FinalStepComponent]
})
export class FinalStepModule { }
