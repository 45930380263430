import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfilComponent } from './perfil.component';
import {PageTitleModule} from '../../../components/page-title/page-title.module';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {IMaskModule} from 'angular-imask';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxCurrencyModule} from 'ngx-currency';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [PerfilComponent],
    imports: [
        CommonModule,
        PageTitleModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        IMaskModule,
        MatIconModule,
        ReactiveFormsModule,
        MatSelectModule,
        NgxCurrencyModule,
        MatCheckboxModule,
        MatAutocompleteModule
    ]
})
export class PerfilModule { }
