import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NovoClienteComponent} from './novo-cliente.component';
import {PageTitleModule} from '../../../components/page-title/page-title.module';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {HttpClientModule} from '@angular/common/http';
import {MatInputModule} from '@angular/material/input';
import {IMaskModule} from 'angular-imask';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
  declarations: [
    NovoClienteComponent

  ],
    imports: [
        CommonModule,
        PageTitleModule,
        RouterModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        HttpClientModule,
        MatInputModule,
        IMaskModule,
      MatDialogModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
    ],
  exports: [
    NovoClienteComponent
  ]
})
export class NovoClienteModule { }
