<div class="MainHeader">
  <mat-sidenav-container (backdropClick)="close()" [hasBackdrop]="true" >
    <mat-sidenav #sidenav
                 mode="side"
                 [(opened)]="isOpen"
                 (keydown.escape)="close()"
                 [mode]="'over'"
    >
      <app-main-menu></app-main-menu>
    </mat-sidenav>

    <mat-sidenav-content class="MainHeader-content">

      <div class="MainHeader-bar">

        <div class="MainHeader-barCentralizer">

          <div class="MainHeader-logoWrapper">
            <img src="assets/images/logo3.svg" alt="">
          </div>

          <button mat-icon-button class="MainHeader-button--menu" (click)="isOpen = !isOpen;">
            <i *ngIf="!isOpen" class="icofont-navigation-menu"></i>
            <i *ngIf="isOpen" class="icofont-close"></i>
          </button>
        </div>

      </div>

      <ng-content></ng-content>

    </mat-sidenav-content>
  </mat-sidenav-container>

</div>
