import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MyGlobalsService} from '../services/my-globals/my-globals.service';
import {EPageLoaderStatus} from '../services/my-globals/definitions';
import {LocalStorageService} from "../services/local-storage.service";

@Injectable()
export class UnauthorizedRequestInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private myGlobals: MyGlobalsService,
    private localStorage: LocalStorageService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((err: any) => {

          if (err instanceof HttpErrorResponse && (err.status == 403 || err.status == 401)) {
            this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

            if (request.body && request.body['username'] != undefined && request.body['password'] != undefined) {
              // login
              this.snackBar.open('Usuário ou senha inválido.', 'ok', {
                duration: 7000,
                panelClass: ['MySnackBar', 'MySnackBar--error'],
              });
            } else {
              // qualquer outra requisição
              this.snackBar.open('É necessário fazer login novamente.', 'ok', {
                duration: 7000,
                panelClass: ['MySnackBar', 'MySnackBar--warning'],
              });
            }

            this.doLogInAgain();

            return of(err as any);
          }

          throw err;
        })
      );
  }

  private doLogInAgain() {
    this.localStorage.clear();
    this.router.navigate(['login']);
  }
}
