import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EmprestimoComponent} from '../emprestimo.component';
import {InfoPessoalComponent} from './info-pessoal.component';
import {PageTitleModule} from '../../../components/page-title/page-title.module';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {IMaskModule} from 'angular-imask';

@NgModule({
  declarations: [InfoPessoalComponent],
  imports: [
    CommonModule,
    PageTitleModule,
    MatInputModule,
    ReactiveFormsModule,
    IMaskModule,
    MatSelectModule,
    MatButtonModule
  ],
  exports:[InfoPessoalComponent]
})
export class InfoPessoalModule { }
