import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CriticalErrorDialogComponent } from './critical-error-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";



@NgModule({
  declarations: [
    CriticalErrorDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ]
})
export class CriticalErrorDialogModule { }
