import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermosUsoComponent } from './termos-uso.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {PageTitleModule} from '../../../components/page-title/page-title.module';

@NgModule({
  declarations: [TermosUsoComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatButtonModule,
    ReactiveFormsModule,
    PageTitleModule
  ],
  exports: [
    TermosUsoComponent
  ]
})
export class TermosUsoModule { }
