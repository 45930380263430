import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';

@Component({
  selector: 'app-user-terms-dialog',
  templateUrl: './user-terms-dialog.component.html',
  styleUrls: ['./user-terms-dialog.component.scss']
})
export class UserTermsDialogComponent {

  public useTerms = '';

  constructor(
    private myGlobals: MyGlobalsService,
    public dialogRef: MatDialogRef<UserTermsDialogComponent>
  ) {
    this.useTerms = this.myGlobals.useTerms;
  }

  public close() {
    this.dialogRef.close();
  }

}
