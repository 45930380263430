<div class="MainMenu">

  <div class="MainMenu-logo">
    <img src="assets/images/logo3.svg" alt="">
  </div>

  <a (click)="closeMainMenu()" [routerLink]="['emprestimo/estados-permitidos']" [routerLinkActive]="'active'">Empréstimo</a>
  <a (click)="closeMainMenu()" [routerLink]="['perfil']" [routerLinkActive]="'active'">Perfil</a>
  <a (click)="closeMainMenu()" [routerLink]="['pedido/lista']" [routerLinkActive]="'active'">Pedidos</a>
  <a (click)="logout()">Sair</a>

  <div class="MainMenu-appVersion">
    <p>v {{appVersion}}</p>
  </div>
</div>
