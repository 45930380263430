<div class="AllowedStates">
  <app-page-title class="AllowedStates-title">Aviso Importante!</app-page-title>

  <div class="AllowedStates-text">
    <p>No momento estamos atendendo <strong>apenas</strong> quem se encontra no <strong>estado do Paraná</strong>.</p>
    <p *ngIf="!isMobile" class="AllowedStates-big mat-elevation-z2">Para continuar é <strong>necessário</strong> que você esteja no seu <strong>celular</strong> e aceite <strong>compartilhar sua localização</strong>.</p>
  </div>

  <form *ngIf="isMobile" [formGroup]="form" (submit)="nextPage();" novalidate>

    <div class="flex-end">
      <button mat-raised-button color="accent">Continuar</button>
    </div>

  </form>

</div>
