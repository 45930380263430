import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MyGlobalsService} from "../../../services/my-globals/my-globals.service";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {StringUtilsService} from "../../../services/utils/string-utils.service";
import {EPageLoaderStatus} from "../../../services/my-globals/definitions";
import {EQuestionAnswerStatus, IGetQuestionsHttpResponse, IQuestionInfo, ISendAnswersHttpResponse} from "./definitions";
import {MyHttpUtils} from "../../../services/utils/HttpUtils";
import {catchError} from "rxjs/operators";
import {ErrorUtilsService} from "../../../services/utils/error/error-utils.service";
import {ProposalService} from "../proposal.service";

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
  form: FormGroup;
  questions: IQuestionInfo[];
  // showReloadButton: boolean = false;
  private questionId: string = '';

  constructor(
    private proposalData: ProposalService,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private myGlobals: MyGlobalsService,
    private http: HttpClient,
    private myHttpUtils: MyHttpUtils,
    private myErrorUtils: ErrorUtilsService,
    private stringUtils: StringUtilsService,
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  sendForm() {
    if (this.form.invalid) {
      return;
    }

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    let answers:string[] = [];
    for(let i = 0; i < this.questions.length; ++i) {
      answers.push(this.form.get(`_${i}`).value);
    }

    this.http.post(`${this.myGlobals.baseUrlApi}/api/ucredit/bigdata/questionAnswers`, {
      TicketId: this.questionId,
      Answers: answers,
      Cpf: '06443819900',
    }, {
      headers: this.myHttpUtils.configBasicAuthHeader()
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        this.myErrorUtils.showHttpError(err.error);

        return this.myHttpUtils.breakObservableChain();
      })
    ).subscribe((data: ISendAnswersHttpResponse) => {
      this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

      if(data.contentResponse.status === EQuestionAnswerStatus.Denied) {
        this.proposalDenied();
        return;

      } else if(data.contentResponse.status === EQuestionAnswerStatus.Error) {
        this.loadQuestions();

        this.snackBar.open('Houve um erro no servidor, tentando carregar novamente as questões.', 'ok', {
          duration: 1000,
          panelClass: ['MySnackBar', 'MySnackBar--error'],
        });

        return;
      }

      this.router.navigate(['emprestimo/info-documentos'], {});
    });
  }

  private initForm() {
    this.form = this.formBuilder.group({});

    this.loadQuestions();
  }

  private loadQuestions() {
    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    const personInfo = this.proposalData.personData;

    const birthRaw = personInfo.dataNascimento;
    const birthDate = birthRaw.substr(0,4) + '-' + birthRaw.substr(4,2) + '-' + birthRaw.substr(6,2);

    this.http.post(`${this.myGlobals.baseUrlApi}/api/ucredit/bigdata/question`, {
      Cpf: personInfo.cpf,
      Name: personInfo.nome,
      Phone: personInfo.telefoneCelular,
      Email: personInfo.email,
      Birthdate: birthDate
    }, {
      headers: this.myHttpUtils.configBasicAuthHeader()
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        this.myErrorUtils.showHttpError(err.error);

        return this.myHttpUtils.breakObservableChain();
      })
    ).subscribe((data: IGetQuestionsHttpResponse) => {

      if(data.contentResponse.status === EQuestionAnswerStatus.Denied) {
        this.proposalDenied();
        return;

      } else if(data.contentResponse.status === EQuestionAnswerStatus.Error) {

        this.snackBar.open('Houve um erro no servidor, tente novamente.', 'ok', {
          duration: 10000,
          panelClass: ['MySnackBar', 'MySnackBar--error'],
        });

        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

        return;
      }

      this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

      this.questionId = data.contentResponse.questionnaireId;

      let formFields = {};
      for(let i = 0; i < data.contentResponse.questions.length; ++i) {
        formFields['_' + i] = ['', Validators.required];
      }

      this.form = this.formBuilder.group(formFields);

      this.questions = data.contentResponse.questions;
    });
  }

  reloadQuestions() {
    this.loadQuestions();
  }

  private proposalDenied() {
    this.http.post(`${this.myGlobals.baseUrlApi}/api/propostas`, this.proposalData.loanData, {
      headers: this.myHttpUtils.configBasicAuthHeader()
    })
      .pipe(
        catchError((err: any) => {
          this.snackBar.open('Houve um erro no servidor.', 'ok', {
            duration: 5000,
            panelClass: ['MySnackBar', 'MySnackBar--error'],
          });

          return this.myHttpUtils.breakObservableChain();
        }))
      .subscribe(() => {
        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);
        this.router.navigate(['emprestimo/info-documentos', "NaoProssegue"], {});
      });
  }
}
