<div class="InfoPessoal">
  <app-page-title>Conte um pouco sobre você</app-page-title>

  <form [formGroup]="mainForm" (submit)="nextPage();" novalidate>

    <mat-form-field class="fullWidth">
      <input
        matInput
        placeholder="Nome Completo"
        formControlName="nome"
        type="text"
      >

      <mat-error *ngIf="mainForm.get('nome').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field class="fullWidth">
      <input
        matInput
        type="tel"
        placeholder="Data de Nascimento"
        name="birthDate"
        formControlName="dataNascimento"
        [imask]="dateMask"
      >

      <mat-error *ngIf="mainForm.get('dataNascimento').hasError('date')">
        Data inválida
      </mat-error>

      <mat-error *ngIf="mainForm.get('dataNascimento').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <mat-select
        class="bigFont"
        placeholder="Sexo"
        formControlName="sexo"
      >
        <mat-option value="F">
          Feminino
        </mat-option>

        <mat-option value="M">
          Masculino
        </mat-option>

      </mat-select>

      <mat-error *ngIf="mainForm.get('sexo').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        [imask]="rgMask"
        type="tel"
        placeholder="RG (identidade)"
        name="rg"
        formControlName="rg"
      >

      <mat-error *ngIf="mainForm.get('rg').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        type="text"
        placeholder="Órgão Emissor (RG)"
        name="rgEmitter"
        formControlName="rgOrgaoEmissor"
      >

      <mat-error *ngIf="mainForm.get('rgOrgaoEmissor').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <mat-select
        class="bigFont"
        placeholder="UF (RG)"
        formControlName="rgUf"
      >

        <mat-option *ngFor="let state of brazilStates" [value]="state.abbr">
          {{state.state}}
        </mat-option>

      </mat-select>

      <mat-error *ngIf="mainForm.get('rgUf').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <mat-select
        class="bigFont"
        placeholder="Estado Civil"
        formControlName="estadoCivil"
      >

        <mat-option *ngFor="let state of civilState" [value]="state.value">
          {{state.text}}
        </mat-option>

      </mat-select>

      <mat-error *ngIf="mainForm.get('estadoCivil').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field class="fullWidth">
      <input
        matInput
        placeholder="Nome de uma Referência"
        formControlName="referenciaNome"
        type="text"
      >

      <mat-error *ngIf="mainForm.get('referenciaNome').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        [imask]="telMask"
        type="tel"
        placeholder="Telefone de uma Referência"
        name="referenciaTelefone"
        formControlName="referenciaTelefone"
      >

      <mat-error *ngIf="mainForm.get('referenciaTelefone').hasError('required')">
        Campo obrigatório
      </mat-error>

      <mat-error *ngIf="mainForm.get('referenciaTelefone').hasError('telephone')">
        Telefone inválido
      </mat-error>

    </mat-form-field>

    <div class="flex-end">
      <button mat-raised-button color="accent">Continuar</button>
    </div>

  </form>

</div>
