import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AllowedStatesComponent} from './allowed-states.component';
import {PageTitleModule} from '../../../components/page-title/page-title.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [AllowedStatesComponent],
  imports: [
    CommonModule,
    PageTitleModule,
    ReactiveFormsModule,
    MatButtonModule
  ],
  exports: [AllowedStatesComponent]
})
export class AllowedStatesModule { }
