<div class="CriticalErrorDialog">
  <h1 class="CriticalErrorDialog-title">
    <i class="icofont-warning"></i>
  </h1>
  <div class="CriticalErrorDialog-content">
    <h3>Atenção!</h3>
    <p>{{errorMsg}}</p>
  </div>
  <div class="CriticalErrorDialog-buttons">
    <button mat-raised-button mat-dialog-close color="accent">OK</button>
  </div>
</div>
