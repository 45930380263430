import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PageLoaderModule} from './components/page-loader/page-loader.module';
import {MainHeaderModule} from './components/main-header/main-header.module';
import {NG_VALIDATORS} from '@angular/forms';
import {cpfValidator} from './form-validators/cpf.validator.directive';
import {MAT_MOMENT_DATE_FORMATS, MatMomentDateModule, MomentDateAdapter} from '@angular/material-moment-adapter';
import {UserTermsDialogComponent} from './components/dialogs/user-terms-dialog/user-terms-dialog.component';
import {UserTermsDialogModule} from './components/dialogs/user-terms-dialog/user-terms-dialog.module';
import {EmprestimoRoutingModule} from './pages/emprestimo/emprestimo-routing.module';
import {ClienteRoutingModule} from './pages/cliente/cliente-routing.module';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localeExtra from '@angular/common/locales/extra/pt';
import {NgxCurrencyModule} from 'ngx-currency';
import {TakePhotoDialogModule} from './components/dialogs/take-photo-dialog/take-photo-dialog.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {UnauthorizedRequestInterceptor} from './http-interceptors/unauthorized-request.interceptor';
import {PedidoRoutingModule} from './pages/pedido/pedido-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {CriticalErrorDialogModule} from "./components/dialogs/critical-error-dialog/critical-error-dialog.module";

registerLocaleData(localePt, 'pt', localeExtra);

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: true
};

@NgModule({
  entryComponents: [
    UserTermsDialogComponent
  ],
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    EmprestimoRoutingModule,
    ClienteRoutingModule,
    PedidoRoutingModule,
    BrowserAnimationsModule,
    MainHeaderModule,
    PageLoaderModule,
    UserTermsDialogModule,
    TakePhotoDialogModule,
    CriticalErrorDialogModule,
    MatMomentDateModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'pt'},
    {provide: NG_VALIDATORS, useExisting: cpfValidator, multi: true},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'pt'},
    {provide: HTTP_INTERCEPTORS, useClass: UnauthorizedRequestInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
