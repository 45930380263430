<div class="InfoParentes">
  <app-page-title>Qual o nome dos seus pais?</app-page-title>

  <form [formGroup]="mainForm" (submit)="nextPage();" novalidate>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        placeholder="Nome da Mãe"
        name="motherName"
        formControlName="nomeMae"
      >

      <mat-error *ngIf="mainForm.get('nomeMae').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        placeholder="Nome do Pai"
        name="fatherName"
        formControlName="nomePai"
      >

      <mat-error *ngIf="mainForm.get('nomePai').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <div class="flex-end">
      <button mat-raised-button color="accent">Continuar</button>
    </div>

  </form>

</div>
