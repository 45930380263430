import {Component, OnInit} from '@angular/core';
import {TakePhotoDialogComponent} from '../../../components/dialogs/take-photo-dialog/take-photo-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MyHttpUtils} from '../../../services/utils/HttpUtils';
import {Router} from '@angular/router';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import {catchError, concatMap} from 'rxjs/operators';
import {ProposalService} from '../proposal.service';
import {StringUtilsService} from '../../../services/utils/string-utils.service';
import {ICredDefenseDocsInfo, ILoanInfo} from '../definitions';
import {EPageLoaderStatus} from '../../../services/my-globals/definitions';
import {ICreateProposalResponse, ICredDefenseCreditRequestResponse, InfoFotoDocsErrResponse} from './definitions';
import {CameraService} from "../../../services/camera/camera.service";
import {PhotoModalParams2} from "../../../components/dialogs/take-photo-dialog/definitions";
import {ECameraOrientation} from "../../../services/camera/definitions";
import {
  CriticalErrorDialogComponent
} from "../../../components/dialogs/critical-error-dialog/critical-error-dialog.component";

@Component({
  selector: 'app-info-foto-docs',
  templateUrl: './info-foto-docs.component.html',
  styleUrls: ['./info-foto-docs.component.scss']
})
export class InfoFotoDocsComponent implements OnInit {

  public photo = '';
  public cnh = '';
  public rg = ['', ''];
  public readonly rgFrontIndex = 0;
  public readonly rgBackIndex = 1;

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    private myHttpUtils: MyHttpUtils,
    private snackBar: MatSnackBar,
    private proposalData: ProposalService,
    private myGlobals: MyGlobalsService,
  ) {
  }

  ngOnInit() {
    let obseravable = null;
    this.proposalData.personData.myUserId = this.myGlobals.userInfo.id;

    if (this.myGlobals.personInfo) {
      obseravable = this.http.put(`${this.myGlobals.baseUrlApi}/api/pessoas/${this.proposalData.personData.pessoaId}`, this.proposalData.personData, {
        headers: this.myHttpUtils.configBasicAuthHeader()
      });
    } else {
      obseravable = this.http.post(`${this.myGlobals.baseUrlApi}/api/pessoas`, this.proposalData.personData, {
        headers: this.myHttpUtils.configBasicAuthHeader()
      });
    }

    obseravable.pipe(
      catchError((err: any) => {
        this.snackBar.open('Houve um erro no servidor.', 'ok', {
          duration: 5000,
          panelClass: ['MySnackBar', 'MySnackBar--error'],
        });

        return this.myHttpUtils.breakObservableChain();
      }),
    )
      .subscribe((data: any) => {
        this.myGlobals.personInfo = data.contentResponse;
      });
  }

  uploadPersonPhoto() {
    const param: PhotoModalParams2 = {
      isPersonPhoto: true,
      isFaceMatch: false,
      camera: {
        orientation: ECameraOrientation.Portrait,
        hasChangeOrientation: false
      }
    };

    const dialogRef = this.dialog.open(TakePhotoDialogComponent, {
      height: window.innerHeight + 'px',
      maxWidth: window.innerWidth + 'px',
      width: window.innerWidth + 'px',
      autoFocus: false,
      panelClass: 'TakePhotoDialog-wrapper',
      data: param
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (!result) {
        return;
      }

      this.photo = result;
    });

  }

  uploadDocumentPhoto(type: 'cnh' | 'rgB' | 'rgF') {
    const param: PhotoModalParams2 = {
      isPersonPhoto: false
    };

    const dialogRef = this.dialog.open(TakePhotoDialogComponent, {
      height: window.innerHeight + 'px',
      maxWidth: window.innerWidth + 'px',
      width: window.innerWidth + 'px',
      autoFocus: false,
      panelClass: 'TakePhotoDialog-wrapper',
      data: param
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (!result) {
        return;
      }

      switch (type) {
        case 'cnh':
          this.cnh = result;
          break;

        case 'rgB':
          this.rg[this.rgBackIndex] = result;
          break;

        case 'rgF':
          this.rg[this.rgFrontIndex] = result;
          break;
      }

    });
  }

  eraseAllPhotos() {
    this.cnh = '';
    this.rg = [];
  }

  sendProposal() {
    if (this.photo === '' || (this.cnh === '' && (this.rg[0] === '' || this.rg[1] === ''))) {
      this.snackBar.open('É necessário tirar a foto da face e dos documentos', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    const documents: Array<{Document:string; Side: string;}> = [];
    let docType = 'Cnh';
    if (this.cnh) {
      documents.push({
        Document: this.cnh,
        Side: 'C'
      });
    }

    if (this.rg[0] !== '') {
      docType = 'Rg';
      documents.push({
        Document: this.rg[this.rgFrontIndex],
        Side: 'A'
      });
    }

    if(this.rg[1] !== '') {
      documents.push({
        Document: this.rg[this.rgBackIndex],
        Side: 'B'
      });
    }

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    this.http.post(`${this.myGlobals.baseUrlApi}/api/ucredit/bigdata/imageanalysis`, {
      FaceMatch: this.photo,
      DocumentType: docType,
      Documents: documents,
      Cpf: '06443819900'
    }, {
      headers: this.myHttpUtils.configBasicAuthHeader()
    })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          const errMsg:string = err.error.contentResponse.mensagem + " Tire novamente a foto dos documentos e a selfie.";

          const dialogRef = this.dialog.open(CriticalErrorDialogComponent, {
            data: errMsg
          });

          return this.myHttpUtils.breakObservableChain();
        }),
        concatMap((data: ICredDefenseCreditRequestResponse) => {
          const loanData: ILoanInfo = this.proposalData.loanData;
          loanData.pessoa_id = this.myGlobals.personInfo.id;
          this.proposalData.setLoanInfo(loanData);

          return this.http.post(`${this.myGlobals.baseUrlApi}/api/propostas`, this.proposalData.loanData, {
            headers: this.myHttpUtils.configBasicAuthHeader()
          })
        }),
        catchError((err: HttpErrorResponse) => {
          this.snackBar.open(err.error?.contentResponse.mensagem, 'ok', {
            duration: 5000,
            panelClass: ['MySnackBar', 'MySnackBar--error'],
          });

          return this.myHttpUtils.breakObservableChain();
        }),
      )
      .subscribe((data: ICreateProposalResponse) => {
        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

        if (data.success) {
          this.myGlobals.personInfo.id = data.contentResponse.pessoa_id;
        }

        this.snackBar.open('Dados enviados com sucesso!', 'ok', {
          duration: 5000,
          panelClass: ['MySnackBar', 'MySnackBar--success'],
        });

        this.router.navigate(['emprestimo/mensagem-sobre-conclusao'], {});

      });
  }
}
