import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MainHeaderComponent} from './main-header.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {MainMenuModule} from '../main-menu/main-menu.module';

@NgModule({
  declarations: [
    MainHeaderComponent
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatButtonModule,
    RouterModule,
    MainMenuModule
  ],
  exports: [
    MainHeaderComponent
  ]
})
export class MainHeaderModule { }
