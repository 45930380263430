import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import {ProposalService} from '../proposal.service';
import {HowReceivePayment, IBankCondeData, IHttpResponseBankList} from './definitions';
import {HttpClient} from '@angular/common/http';
import {MyHttpUtils} from '../../../services/utils/HttpUtils';
import {catchError, map, startWith} from 'rxjs/operators';
import {EPageLoaderStatus} from '../../../services/my-globals/definitions';
import {Observable} from 'rxjs';
import {IBankInfo} from '../definitions';
import {BankUtilsService} from '../../../services/utils/bank-utils.service';
import {FormUtilsService} from '../../../services/utils/form-utils.service';

@Component({
  selector: 'app-info-banco',
  templateUrl: './info-banco.component.html',
  styleUrls: ['./info-banco.component.scss']
})
export class InfoBancoComponent implements OnInit {

  public mainForm: FormGroup;
  public howReceivePayment = HowReceivePayment;
  public bankInfoData: Array<IBankCondeData>;
  public bankInfoDataFiltered: Observable<Array<IBankCondeData>>;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private myGlobals: MyGlobalsService,
    private proposalData: ProposalService,
    private myHttpUtils: MyHttpUtils,
    private http: HttpClient,
    private bankUtils: BankUtilsService,
  ) {
  }

  ngOnInit() {
    this.initForm();

    this.loadBanks();
  }

  private initForm() {
    this.mainForm = this.formBuilder.group({
      tipoContaPagto: ['',
        [Validators.required]
      ],
      bancoPagtoName: ['',
        [Validators.required]
      ],
      bancoPagto: [''],
      agenciaPagto: ['',
        [Validators.required]
      ],
      digitoAgenciaPagto: [''],
      contaPagto: ['',
        [Validators.required]
      ],
      digitoContaPagto: [''],
    });

    this.bankInfoDataFiltered = this.mainForm.get('bancoPagtoName').valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterBanks(value))
      );
  }

  nextPage() {
    if (!this.mainForm.valid) {
      this.snackBar.open('Faltou preencher os campos obrigatórios', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    const formData: IBankInfo = this.mainForm.value;
    formData.bancoPagto = this.bankUtils.bankNameToNumber(formData.bancoPagtoName, this.bankInfoData);

    if (formData.bancoPagto == -1) {
      this.snackBar.open('Nome do banco está incorreto', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    this.bankUtils.validateBankInfo(this.mainForm.value)
      .subscribe((res: string) => {
        if (res) {
          this.snackBar.open(res, 'ok', {
            duration: 5000,
            panelClass: ['MySnackBar', 'MySnackBar--error'],
          });

          return;
        }

        this.proposalData.setBankInfo(formData);

        this.router.navigate(['emprestimo/questionario'], {});
      });
  }

  private loadBanks() {
    this.bankUtils.loadBanks()
      .subscribe((res: IHttpResponseBankList) => {
        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

        this.bankInfoData = this.bankUtils.generateBankNameWithNumber(res.contentResponse);

        this.updateForm();
      });
  }

  private filterBanks(val: string): IBankCondeData[] {
    return this.bankUtils.filterBanks(val, this.bankInfoData);
  }

  private updateForm() {
    const person = this.myGlobals.personInfo;

    if (!person) {
      return;
    }

    let bankSelected = this.bankUtils.bankNumberToName(person.bancoPagto, this.bankInfoData);

    this.mainForm.setValue({
      tipoContaPagto: person.tipoContaPagto,
      bancoPagtoName: bankSelected,
      bancoPagto: person.bancoPagto,
      agenciaPagto: person.agenciaPagto,
      digitoAgenciaPagto: person.digitoAgenciaPagto,
      contaPagto: person.contaPagto,
      digitoContaPagto: person.digitoContaPagto,
    });
  }
}
