<div class="DetalhesPedido">

  <div class="DetalhesPedido-top">
    <button mat-icon-button type="button" (click)="gotToListaPedidos()" color="primary">
      <i class="icofont-arrow-left"></i>
    </button>

    <h1 class="DetalhesPedido-title">Detalhes</h1>
  </div>

  <mat-card *ngIf="contractDetails">

    <div class="DetalhesPedido-contractInfo">
      <p>Contrato: <strong>{{contractDetails.Contrato}}</strong></p>
      <p>Situação: <strong>{{contractDetails.Situacao}}</strong></p>
      <p>Prestações Pagas: <strong>{{contractDetails.QtdPrestacoesPagas}} / {{contractDetails.TotalPrestacoes}}</strong></p>
      <p>Valor Financiado: <strong>{{contractDetails.ValorFinanciadoFrm}}</strong></p>
      <p>Valor da Prestação: <strong>{{contractDetails.ValorPrestacaoFrm}}</strong></p>
      <p>Atraso: <strong>{{contractDetails.Atraso}} dia(s)</strong></p>
    </div>
  </mat-card>

  <mat-card class="DetalhesPedido-installments" *ngIf="contractDetails">
    <div class="DetalhesPedido-installment" *ngFor="let item of contractDetails.Parcelas">
      <p>Parcela: <strong>{{item.Prestacao}}</strong></p>
      <p>Vencimento: <strong>{{item.Vencimento}}</strong></p>
      <p>Valor: <strong>{{item.ValorFrm}}</strong></p>
      <p>Código de barras:</p>
      <p class="DetalhesPedido-barCodeText">
        <button matRipple (click)="copyBarCode(item.linhaDigitavel)">
          <strong>
            {{item.linhaDigitavel}}
            <i class="icofont-ui-copy"></i>
          </strong>
        </button>
      </p>
      <p
        [ngClass]="installmentSituationCss(item)"
        class="DetalhesPedido-installmentSituation">{{item.Situacao}}</p>
    </div>
  </mat-card>

</div>
