import {ApplicationRef, Component, OnInit} from '@angular/core';
import {MyGlobalsService} from './services/my-globals/my-globals.service';
import {delay, filter, map} from 'rxjs/operators';
import {EPageLoaderStatus} from './services/my-globals/definitions';
import {RouterOutlet} from '@angular/router';
import {PageTransitionAnim} from './animations/page-transition';
import {isMobile} from './services/my-globals/is-mobile';
import {interval} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [PageTransitionAnim],
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit {

  public showPageLoader = false;
  private onlyOneRegisterToUpdateSW = false;

  constructor(
    private snackBar: MatSnackBar,
    private applicationRef: ApplicationRef,
    private updates: SwUpdate,
    private myGlobals: MyGlobalsService
  ) {
    this.myGlobals.isMobile = isMobile();
  }

  ngOnInit(): void {
    this.initServiceWorker();

    this.myGlobals.getGlobalBarLoaderStatus().pipe(delay(0)).subscribe((value: EPageLoaderStatus) => {
      this.showPageLoader = value === EPageLoaderStatus.visible;
    });
  }

  initServiceWorker() {
    if (this.updates.isEnabled) {
      // WARNING: If the interval function is called out of this event, the app dont will be stable and the service worker will not function
      this.applicationRef.isStable.subscribe(isStable => {
        if (isStable) {

          if (!this.onlyOneRegisterToUpdateSW) {
            this.onlyOneRegisterToUpdateSW = true;
            // milliseconds * seconds * minutes
            // interval(1000 * 60 * 3).subscribe(() => {
            interval(1000 * 10 * 3).subscribe(() => {
              return this.updates?.checkForUpdate();
            });

            this.updates?.versionUpdates.pipe(
              filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
              map(evt => ({
                type: 'UPDATE_AVAILABLE',
                current: evt.currentVersion,
                available: evt.latestVersion,
              })))
              .subscribe((evt) => {

                this.updates?.activateUpdate()
                  .then(() => {
                    alert('Atualizando o app.');
                    document?.location?.reload();
                  });

              });

          }
        }
      });

    }
  }

  getRouterOutletState(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
}
