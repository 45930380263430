import {Injectable} from '@angular/core';
import {
  IAddressInfo,
  IBankInfo,
  IContactInfo, ICredDefenseInfo,
  ILoanInfo,
  IParentInfo,
  IPersonalInfo,
  IUseTermsInfo,
  IWorkInfo
} from './definitions';
import {IPersonInfo} from './definitions';
import {StringUtilsService} from '../../services/utils/string-utils.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {

  private _personData: IPersonInfo = null;
  private _loanData: ILoanInfo = null;
  private _credDefData: ICredDefenseInfo = null;

  constructor(
    private myStringUtils: StringUtilsService
  ) {
    this._personData = this.initPersonObj();
    this._loanData = this.initLoanObj();
    this._credDefData = this.initCredDefenseObj();
  }

  setAddressData(data: IAddressInfo) {
    data.cep = this.myStringUtils.removeCepMask(data.cep);

    this._personData = Object.assign(this._personData, data);
  }

  setParentsData(data: IParentInfo) {
    this._personData = Object.assign(this._personData, data);
  }

  setPersonalData(data: IPersonalInfo) {
    data.dataNascimento = this.myStringUtils.convertDateBrToUs(data.dataNascimento);
    data.dataNascimento = this.myStringUtils.removeDateMask(data.dataNascimento);
    data.rg = this.myStringUtils.removeRgMask(data.rg);

    data.referenciaTelefone = this.myStringUtils.removeTelMask(data.referenciaTelefone);

    this._personData = Object.assign(this._personData, data);
  }

  setWorkData(data: IWorkInfo) {
    data.dataAdmissaoTrabalho = this.myStringUtils.convertDateBrToUs(data.dataAdmissaoTrabalho);
    data.dataAdmissaoTrabalho = this.myStringUtils.removeDateMask(data.dataAdmissaoTrabalho);
    data.empresaCep = this.myStringUtils.removeCepMask(data.empresaCep);
    data.empresaTelefone = this.myStringUtils.removeTelMask(data.empresaTelefone);

    this._personData = Object.assign(this._personData, data);
  }

  setUseTermsData(data: IUseTermsInfo, useTerms: string) {
    data.termosDeUso = useTerms;

    this._loanData = Object.assign(this._loanData, data);
  }

  setBankInfo(data: IBankInfo) {
    this._personData = Object.assign(this._personData, data);
  }

  setContactInfo(data: IContactInfo) {
    data.telefoneCelular = this.myStringUtils.removeTelMask(data.telefoneCelular);
    data.telefoneResidencial = this.myStringUtils.removeTelMask(data.telefoneResidencial);

    this._personData = Object.assign(this._personData, data);
  }

  setLoanInfo(data: ILoanInfo) {
    this._loanData = Object.assign(this._loanData, data);
  }

  setCredDefInfo(data: ICredDefenseInfo) {
    this._credDefData = Object.assign(this._credDefData, data);
  }

  get personData(): IPersonInfo {
    return this._personData;
  }

  get loanData(): ILoanInfo {
    return this._loanData;
  }

  get credDefData(): ICredDefenseInfo {
    return this._credDefData;
  }

  private initLoanObj(): ILoanInfo {
    return {
      pessoa_id: 0,
      plano: 0,
      prazo: 0,
      vencimento_inicial: '',
      valor: 0,
      financiado: 0,
      entrada: 0,
      tac: 0,
      iof: 0,
      prestacao: 0,
      termosDeUso: '',
      aceito: false,
      agente_vendas_id: 0,
    }
  }

  private initCredDefenseObj(): ICredDefenseInfo {
    return {
      identifier_code: '',
      document_type: 0,
      place_id: 0,
      name: '',
      birth_date: '',
      postal_code: '',
      assessment_type: 0,
      query_type: 0,
      photo: '',
      document: []
    };
  }

  private initPersonObj(): IPersonInfo {
    return {
      pessoaId: 0,
      estado: '',
      myUserId: 0,
      cpf: '',
      telefoneCelular: '',
      telefoneResidencial: '',
      email: '',
      cep: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      nomeMae: '',
      nomePai: '',
      nome: '',
      dataNascimento: '',
      sexo: '',
      rg: '',
      rgOrgaoEmissor: '',
      rgUf: '',
      estadoCivil: 0,
      nacionalidade: '',
      ufNaturalidade: '',
      referenciaNome: '',
      referenciaTelefone: '',
      cargoFuncaoTrabalho: '',
      dataAdmissaoTrabalho: '',
      situacaoTrabalho: 0,
      renda: 0,
      empresaNome: '',
      empresaTelefone: '',
      empresaCep: '',
      empresaEndereco: '',
      empresaEnderecoNumero: '',
      empresaBairro: '',
      empresaCidade: '',
      empresaUf: '',
      tipoContaPagto: '',
      bancoPagto: 0,
      bancoPagtoName: '',
      agenciaPagto: '',
      digitoAgenciaPagto: '',
      contaPagto: '',
      digitoContaPagto: ''
    };
  }

  clearData() {
    this._personData = this.initPersonObj();
    this._loanData = this.initLoanObj();
    this._credDefData = this.initCredDefenseObj();
  }
}
