import {INewClient} from '../../pages/cliente/novo-cliente/definitions';

export const CpfMask = {
  mask: '000.000.000-00'
};

export const TelMask = {
  mask: [
    {
      mask: '(00) 0000-0000'
    },
    {
      mask: '(00) 0 0000-0000'
    }
  ]
};

export const RgMask = {
  mask: [
    {
      mask: '0.000.000-0'
    },
    {
      mask: '00.000.000-0'
    },
    {
      mask: '000.000.000-0'
    },
    {
      mask: '000.000.000-00'
    }
  ]
};

export const EmailMask = {
  mask: /^\S*@?\S*$/
};

export const CEPMask = {
  mask: '00000-000'
};

export const BrazilStatesList = [
  {state: 'Acre', abbr: 'AC'},
  {state: 'Alagoas', abbr: 'AL'},
  {state: 'Amapá', abbr: 'AP'},
  {state: 'Amazonas', abbr: 'AM'},
  {state: 'Bahia', abbr: 'BA'},
  {state: 'Ceará', abbr: 'CE'},
  {state: 'Distrito Federal', abbr: 'DF'},
  {state: 'Espírito Santo', abbr: 'ES'},
  {state: 'Goiás', abbr: 'GO'},
  {state: 'Maranhão', abbr: 'MA'},
  {state: 'Mato Grosso', abbr: 'MT'},
  {state: 'Mato Grosso do Sul', abbr: 'MS'},
  {state: 'Minas Gerais', abbr: 'MG'},
  {state: 'Pará', abbr: 'PA'},
  {state: 'Paraíba', abbr: 'PB'},
  {state: 'Paraná', abbr: 'PR'},
  {state: 'Pernambuco', abbr: 'PE'},
  {state: 'Piauí', abbr: 'PI'},
  {state: 'Rio de Janeiro', abbr: 'RJ'},
  {state: 'Rio Grande do Norte', abbr: 'RN'},
  {state: 'Rio Grande do Sul', abbr: 'RS'},
  {state: 'Rondônia', abbr: 'RO'},
  {state: 'Roraima', abbr: 'RR'},
  {state: 'Santa Catarina', abbr: 'SC'},
  {state: 'São Paulo', abbr: 'SP'},
  {state: 'Sergipe', abbr: 'SE'},
  {state: 'Tocantins', abbr: 'TO'},
];

// Nunca altere a ordem dessa matriz! Pois existem trechos de código que usam ela diretamente
export const WorkState = [
  {value: '6', text: 'Aposentado'},
  {value: '1', text: 'Assalariado'},
  {value: '3', text: 'Autonomo'},
  {value: '8', text: 'Empresario'},
  {value: '2', text: 'Funcionario Publico'},
  {value: '5', text: 'Militar'},
  {value: '10', text: 'Outras'},
  {value: '9', text: 'Pensionista'},
  {value: '4', text: 'Profissional Liberal'},
  {value: '7', text: 'Rural'},
];

// Nunca altere a ordem dessa matriz! Pois existem trechos de código que usam ela diretamente
export const CivilState = [
  {value: '1', text: 'Casado'},
  {value: '2', text: 'Solteiro'},
  {value: '3', text: 'Viúvo'},
  {value: '4', text: 'Separado'},
  {value: '5', text: 'Divorciado'},
];

export enum EPageLoaderStatus {
  visible = 'visible',
  hidden = 'hidden'
}

export enum EMainMenuStatus {
  visible = 'visible',
  hidden = 'hidden'
}

export interface IUserInfo extends INewClient {
}

