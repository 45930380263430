import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import {BrazilStatesList, CEPMask, EPageLoaderStatus, TelMask, WorkState} from '../../../services/my-globals/definitions';
import {DateUtilsService} from '../../../services/utils/date-utils.service';
import {ProposalService} from '../proposal.service';
import {FormUtilsService} from '../../../services/utils/form-utils.service';
import {StringUtilsService} from '../../../services/utils/string-utils.service';
import {dateValidator} from '../../../form-validators/date.validator.directive';
import {IApiCep} from '../../cliente/perfil/definitions';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-info-trabalho',
  templateUrl: './info-trabalho.component.html',
  styleUrls: ['./info-trabalho.component.scss']
})
export class InfoTrabalhoComponent implements OnInit, AfterViewInit {

  public mainForm: FormGroup;
  public workState = WorkState;
  public telMask = TelMask;
  public cepMask = CEPMask;
  public dateMask = {};
  public brazilStates = BrazilStatesList;
  public requestingCep = false;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private dateUtils: DateUtilsService,
    private myFormUtils: FormUtilsService,
    private myGlobals: MyGlobalsService,
    private proposalData: ProposalService,
    private myStringUtils: StringUtilsService,
    private http: HttpClient
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateForm();
    }, 0);
  }

  nextPage() {
    if (!this.mainForm.valid) {
      this.snackBar.open('Faltou preencher os campos obrigatórios', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    this.proposalData.setWorkData(this.mainForm.value);

    this.router.navigate(['emprestimo/info-endereco'], {});
  }

  private initForm() {
    this.dateMask = this.dateUtils.configAdmissionDateMask();

    this.mainForm = this.formBuilder.group({
      cargoFuncaoTrabalho: ['', [Validators.required]],
      renda: ['', [Validators.required]],
      dataAdmissaoTrabalho: ['', [Validators.required, dateValidator]],
      situacaoTrabalho: ['', [Validators.required]],
      empresaNome: ['', [Validators.required]],
      empresaTelefone: ['', [Validators.required]],
      empresaCep: ['', [Validators.required]],
      empresaEndereco: ['', [Validators.required]],
      empresaEnderecoNumero: ['', [Validators.required]],
      empresaBairro: ['', [Validators.required]],
      empresaCidade: ['', [Validators.required]],
      empresaUf: ['', [Validators.required]],
    });
  }

  private updateForm() {
    const person = this.myGlobals.personInfo;

    if (!person) {
      return;
    }

    let date = person.dataAdmissaoTrabalho.date.substr(0, 10);
    date = this.myStringUtils.convertDateUsToBr(date);

    this.mainForm.setValue({
      cargoFuncaoTrabalho: person.cargoFuncaoTrabalho,
      renda: person.renda,
      dataAdmissaoTrabalho: date,
      situacaoTrabalho: person.situacaoTrabalho + '',
      empresaNome: person.empresaNome ? person.empresaNome : '',
      empresaTelefone: person.empresaTelefone ? person.empresaTelefone : '',
      empresaCep: person.empresaCep ? person.empresaCep : '',
      empresaEndereco: person.empresaEndereco ? person.empresaEndereco : '',
      empresaEnderecoNumero: person.empresaEnderecoNumero ? person.empresaEnderecoNumero : '',
      empresaBairro: person.empresaBairro ? person.empresaBairro : '',
      empresaCidade: person.empresaCidade ? person.empresaCidade : '',
      empresaUf: person.empresaUf ? person.empresaUf : '',
    });
  }

  showClearFieldBtn(str: string) {
    return !!this.mainForm.get(str).value;
  }

  onClickClearField(str: string) {
    const val = {};
    val[str] = '';

    this.mainForm.patchValue(val);
  }

  onClickSearchCep() {
    let cep: string = this.mainForm.get('empresaCep').value;

    if (cep.length !== 9 || this.requestingCep) {
      return;
    }

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    this.requestingCep = true;

    cep = this.myStringUtils.removeCepMask(cep);
    this.http.get(this.myGlobals.getCepUrl(cep))
      .subscribe((address: IApiCep) => {

        this.mainForm.get('empresaEnderecoNumero').setValue('');
        this.mainForm.get('empresaEndereco').setValue(address.logradouro);
        this.mainForm.get('empresaBairro').setValue(address.bairro);
        this.mainForm.get('empresaCidade').setValue(address.localidade);
        this.mainForm.get('empresaUf').setValue(address.uf);

        this.requestingCep = false;

        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);
      });
  }
}
