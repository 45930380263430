import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {MyHttpUtils} from '../../../services/utils/HttpUtils';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import {BrazilStatesList, CEPMask, EPageLoaderStatus} from '../../../services/my-globals/definitions';
import {ProposalService} from '../proposal.service';
import {IApiCep} from '../../cliente/perfil/definitions';
import {StringUtilsService} from '../../../services/utils/string-utils.service';
import {FormUtilsService} from '../../../services/utils/form-utils.service';

@Component({
  selector: 'app-info-endereco',
  templateUrl: './info-endereco.component.html',
  styleUrls: ['./info-endereco.component.scss']
})
export class InfoEnderecoComponent implements OnInit, AfterViewInit {

  public mainForm: FormGroup;
  public cepMask = CEPMask;
  public brazilStates = BrazilStatesList;
  public requestingCep = false;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private myGlobals: MyGlobalsService,
    private http: HttpClient,
    private stringUtils: StringUtilsService,
    private proposalData: ProposalService,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateForm();
    }, 0);
  }

  nextPage() {
    if(!this.mainForm.valid) {
      this.snackBar.open('Faltou preencher os campos obrigatórios', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    this.proposalData.setAddressData(this.mainForm.value);

    this.router.navigate(['emprestimo/info-banco'], {});
  }

  private initForm() {

    this.mainForm = this.formBuilder.group({
      cep: ['', [
        Validators.required
      ]],
      endereco: ['', [
        Validators.required
      ]],
      numero: ['', [
        Validators.required
      ]],
      complemento: ['', []],
      bairro: ['', [
        Validators.required
      ]],
      cidade: ['', [
        Validators.required
      ]],
      estado: ['', [
        Validators.required
      ]]
    });
  }

  showClearFieldBtn(str: string) {
    return !!this.mainForm.get(str).value;
  }

  onClickClearField(str: string) {
    const val = {};
    val[str] = '';

    this.mainForm.patchValue(val);
  }

  onClickSearchCep() {
    let cep: string = this.mainForm.get('cep').value;

    if (cep.length !== 9 || this.requestingCep) {
      return;
    }

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    this.requestingCep = true;

    cep = this.stringUtils.removeCepMask(cep);
    this.http.get(this.myGlobals.getCepUrl(cep))
      .subscribe((address: IApiCep) => {

        this.mainForm.get('numero').setValue('');
        this.mainForm.get('endereco').setValue(address.logradouro);
        this.mainForm.get('complemento').setValue(address.complemento);
        this.mainForm.get('bairro').setValue(address.bairro);
        this.mainForm.get('cidade').setValue(address.localidade);
        this.mainForm.get('estado').setValue(address.uf);

        this.requestingCep = false;

        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);
      });
  }

  private updateForm() {
    const person = this.myGlobals.personInfo;

    if (!person) {
      return;
    }

    this.mainForm.setValue({
      cep: person.cep,
      endereco: person.endereco,
      numero: person.numero,
      complemento: person.complemento,
      bairro: person.bairro,
      cidade: person.cidade,
      estado: person.estado,
    });
  }
}
