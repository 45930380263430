import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DocPhotoBlockComponent} from './doc-photo-block.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [DocPhotoBlockComponent],
  imports: [
    CommonModule,
    MatButtonModule
  ],
  exports: [DocPhotoBlockComponent]
})
export class DocPhotoBlockModule { }
