import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';
import IHereGeolocation from './hereGeolocation';
import {catchError} from 'rxjs/operators';
import {MyHttpUtils} from '../../../services/utils/HttpUtils';
import {EPageLoaderStatus} from '../../../services/my-globals/definitions';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import {ProposalService} from '../proposal.service';
import {
  CriticalErrorDialogComponent
} from "../../../components/dialogs/critical-error-dialog/critical-error-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-allowed-states',
  templateUrl: './allowed-states.component.html',
  styleUrls: ['./allowed-states.component.scss']
})
export class AllowedStatesComponent implements OnInit {
  public form: FormGroup;
  public isMobile = false;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private httpUtils: MyHttpUtils,
    private proposalData: ProposalService,
  private myGlobals: MyGlobalsService,
  private dialog: MatDialog,
) {
    this.proposalData.clearData();
    this.isMobile = this.myGlobals.isMobile;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});

    if(this.myGlobals.personInfo) {
      this.proposalData.personData.myUserId = this.myGlobals.userInfo.id;
      this.proposalData.personData.pessoaId = this.myGlobals.personInfo.id;
    }
  }

  nextPage() {
    if (!navigator.geolocation) {
      this.snackBar.open('É necessário ligar o GPS para prosseguir', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

    navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
      let urlParam = 'https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json?';
      let proxParam = `prox=${position.coords.latitude},${position.coords.longitude},250`;
      let miscParam = '&mode=retrieveAddresses&maxresults=1&gen=9&apiKey=8qAWqNhcFCNEWmo5FCMk3BoHmW7oqdW4mjr96QzBqwY';

      this.http.get(`${urlParam}${proxParam}${miscParam}`)
        .pipe(
          catchError((err) => {


            return this.httpUtils.breakObservableChain();
          })
        )
        .subscribe((data: IHereGeolocation) => {

          let address = data.Response.View[0].Result[0].Location.Address;

          if(address.Country === 'BRA' && address.State === 'PR') {
            this.router.navigate(['emprestimo/termos-uso'], {});
            return;
          }

          this.snackBar.open('Infelizmente não estamos atendendo seu estado.', 'ok', {
            duration: 7500,
            panelClass: ['MySnackBar', 'MySnackBar--error'],
          });

        });
    }, () => {
      this.snackBar.open('É necessário compartilhar sua localização', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    });

  }
}
