import {AfterViewInit, Component, OnInit} from '@angular/core';
import {EmailMask, TelMask} from '../../../services/my-globals/definitions';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {cellphoneValidator} from '../../../form-validators/cellphone.validator.directive';
import {telephoneValidator} from '../../../form-validators/telephone.validator.directive';
import {ProposalService} from '../proposal.service';
import {FormUtilsService} from '../../../services/utils/form-utils.service';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';

@Component({
  selector: 'app-info-contato',
  templateUrl: './info-contato.component.html',
  styleUrls: ['./info-contato.component.scss']
})
export class InfoContatoComponent implements OnInit, AfterViewInit {

  public emailMask = EmailMask;
  public telMask = TelMask;
  public mainForm: FormGroup;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private proposalData: ProposalService,
    private formBuilder: FormBuilder,
    private myFormUtils: FormUtilsService,
    private myGlobals: MyGlobalsService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateForm();
    }, 0);
  }

  nextPage() {
    if(!this.mainForm.valid) {
      this.snackBar.open('Faltou preencher os campos obrigatórios', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    this.proposalData.setContactInfo(this.mainForm.value);

    this.router.navigate(['emprestimo/info-parentes'], {});
  }

  private initForm() {
    const personInfo = this.myGlobals.personInfo;

    this.mainForm = this.formBuilder.group({
      telefoneCelular: ['', [
        Validators.required,
        cellphoneValidator
      ]],
      telefoneResidencial: ['', [
        Validators.required,
        telephoneValidator
      ]],
      email: ['', [
        Validators.required,
        Validators.email
      ]],

    });
  }

  private updateForm() {
    const person = this.myGlobals.personInfo;

    if (!person) {
      return;
    }

    this.mainForm.setValue({
      telefoneCelular: person.telefoneCelular,
      telefoneResidencial: person.telefoneResidencial,
      email: person.email,
    });
  }
}
