import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-confirm-photo2',
  templateUrl: './confirm-photo2.component.html',
  styleUrls: ['./confirm-photo2.component.scss']
})
export class ConfirmPhoto2Component implements OnInit {

  @Input() photo: string;
  @Input() photoError: string;
  @Input() isLandscape: boolean;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() send: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
  }

  sendPhoto() {
    this.send.emit();
  }

  cancelPhoto() {
    this.cancel.emit();
  }

}
