import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DetalhesPedidoComponent} from './detalhes-pedido.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [DetalhesPedidoComponent],
    imports: [
        CommonModule,
        MatExpansionModule,
        MatButtonModule,
        MatCardModule,
        MatRippleModule
    ],
  exports: [DetalhesPedidoComponent]
})
export class DetalhesPedidoModule { }
