import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {PageTransitionAnim} from '../../animations/page-transition';
import {ILoginInfoDb} from "../login/definitions";
import {MyGlobalsService} from "../../services/my-globals/my-globals.service";
import {LocalStorageService} from "../../services/local-storage.service";

@Component({
  selector: 'app-emprestimo',
  templateUrl: './emprestimo.component.html',
  animations: [PageTransitionAnim],
  styleUrls: ['./emprestimo.component.scss']
})
export class EmprestimoComponent implements OnInit {

  constructor(
    private myGlobals: MyGlobalsService,
    private localStorage: LocalStorageService
  ) {
  }

  getRouterOutletState(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  ngOnInit(): void {
    const storageData:ILoginInfoDb = this.localStorage.get('userData');

    this.myGlobals.userToken = storageData.userToken;
    this.myGlobals.userInfo = storageData.userInfo;
    this.myGlobals.personInfo = storageData.personInfo;
  }
}
