<div class="TermosUso">
  <app-page-title>Termos de Uso</app-page-title>

  <div class="TermosUso-text" [innerHTML]="useTerms"></div>

  <form [formGroup]="mainForm" (submit)="nextPage();" novalidate>
    <mat-checkbox formControlName="aceito" value="aceito">Li e aceito os termos</mat-checkbox>

    <div class="flex-end">
      <button mat-raised-button color="accent">Continuar</button>
    </div>

  </form>

</div>
