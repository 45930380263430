import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {InfoFotoDocsComponent} from './info-foto-docs.component';
import {PageTitleModule} from '../../../components/page-title/page-title.module';
import {ReactiveFormsModule} from '@angular/forms';
import {DocPhotoBlockModule} from '../../../components/doc-photo-block/doc-photo-block.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [InfoFotoDocsComponent],
  imports: [
    CommonModule,
    PageTitleModule,
    ReactiveFormsModule,
    DocPhotoBlockModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
  ],
  exports: [InfoFotoDocsComponent]
})
export class InfoFotoDocsModule { }
