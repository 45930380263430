import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {EmprestimoComponent} from './emprestimo.component';
import {EmprestimoModule} from './emprestimo.module';
import {TermosUsoComponent} from './termos-uso/termos-uso.component';
import {TermosUsoModule} from './termos-uso/termos-uso.module';
import {AuthGuard} from '../../route-guard/auth.guard';
import {AllowedStatesComponent} from './allowed-states/allowed-states.component';
import {AllowedStatesModule} from './allowed-states/allowed-states.module';
import {InfoPessoalComponent} from './info-pessoal/info-pessoal.component';
import {InfoEmprestimoComponent} from './info-emprestimo/info-emprestimo.component';
import {InfoEmprestimoModule} from './info-emprestimo/info-emprestimo.module';
import {InfoEnderecoModule} from './info-endereco/info-endereco.module';
import {InfoPessoalModule} from './info-pessoal/info-pessoal.module';
import {InfoTrabalhoModule} from './info-trabalho/info-trabalho.module';
import {InfoTrabalhoComponent} from './info-trabalho/info-trabalho.component';
import {InfoEnderecoComponent} from './info-endereco/info-endereco.component';
import {InfoParentesComponent} from './info-parentes/info-parentes.component';
import {InfoParentesModule} from './info-parentes/info-parentes.module';
import {InfoContatoComponent} from './info-contato/info-contato.component';
import {InfoContatoModule} from './info-contato/info-contato.module';
import {InfoFotoDocsComponent} from './info-foto-docs/info-foto-docs.component';
import {InfoFotoDocsModule} from './info-foto-docs/info-foto-docs.module';
import {InfoBancoComponent} from './info-banco/info-banco.component';
import {InfoBancoModule} from './info-banco/info-banco.module';
import {FinalStepComponent} from './final-step/final-step.component';
import {FinalStepModule} from './final-step/final-step.module';
import {InfoVendedorComponent} from './info-vendedor/info-vendedor.component';
import {InfoVendedorModule} from './info-vendedor/info-vendedor.module';
import {QuestionnaireComponent} from "./questionnaire/questionnaire.component";
import {QuestionnaireModule} from "./questionnaire/questionnaire.module";

const routes: Routes = [
  {
    path: 'info-documentos',
    component: InfoFotoDocsComponent
  },
  {
    path: 'emprestimo',
    component: EmprestimoComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'estados-permitidos',
        component: AllowedStatesComponent
      },
      {
        path: 'termos-uso',
        component: TermosUsoComponent
      },
      {
        path: 'info-vendedor',
        component: InfoVendedorComponent
      },
      {
        path: 'info-emprestimo',
        component: InfoEmprestimoComponent
      },
      {
        path: 'info-pessoal',
        component: InfoPessoalComponent
      },
      {
        path: 'info-contato',
        component: InfoContatoComponent
      },
      {
        path: 'info-parentes',
        component: InfoParentesComponent
      },
      {
        path: 'info-trabalho',
        component: InfoTrabalhoComponent
      },
      {
        path: 'info-endereco',
        component: InfoEnderecoComponent
      },
      {
        path: 'info-banco',
        component: InfoBancoComponent
      },
      {
        path: 'questionario',
        component: QuestionnaireComponent
      },
      {
        path: 'info-documentos',
        component: InfoFotoDocsComponent
      },
      {
        path: 'mensagem-sobre-conclusao/:status',
        component: FinalStepComponent
      },
    ]
  },
];

@NgModule({
  imports: [
    EmprestimoModule,
    TermosUsoModule,
    InfoVendedorModule,
    AllowedStatesModule,
    InfoEmprestimoModule,
    InfoEnderecoModule,
    InfoPessoalModule,
    InfoTrabalhoModule,
    InfoParentesModule,
    InfoContatoModule,
    InfoFotoDocsModule,
    InfoBancoModule,
    FinalStepModule,
    QuestionnaireModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class EmprestimoRoutingModule {
}
