import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MyGlobalsService} from '../../services/my-globals/my-globals.service';
import {catchError} from 'rxjs/operators';
import {MyHttpUtils} from '../../services/utils/HttpUtils';
import {ILoginInfoDb, ILoginResponse} from './definitions';
import {EPageLoaderStatus} from '../../services/my-globals/definitions';
import {ErrorUtilsService} from '../../services/utils/error/error-utils.service';
import {LocalStorageService} from "../../services/local-storage.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public recoveryForm: FormGroup;
  public showLogin: boolean = true;

  public afterLoginRoute: string;
  public localDatabaseName: string;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private myGlobals: MyGlobalsService,
    private myErrorUtils: ErrorUtilsService,
    private myHttpUtils: MyHttpUtils,
    private localStorage: LocalStorageService
  ) {
    this.localDatabaseName = myGlobals.localDatabaseName;
    this.afterLoginRoute = myGlobals.afterLoginRoute;
  }

  ngOnInit() {
    this.initForm();

    const storageData:ILoginInfoDb = this.localStorage.get('userData');

    if(storageData) {
      this.myGlobals.userToken = storageData.userToken;
      this.myGlobals.userInfo = storageData.userInfo;
      this.myGlobals.personInfo = storageData.personInfo;

      this.router.navigate([this.afterLoginRoute], {});
    }

  }

  private initForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    this.recoveryForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  public submitLogin() {
    if (!this.loginForm.valid) {
      return;
    }

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    this.http.post(`${this.myGlobals.baseUrlApi}/login_check`, {
      username: this.loginForm.get('email').value,
      password: this.loginForm.get('password').value
    })
      .pipe(
        catchError((err: HttpErrorResponse) => {

          if(err?.error?.message != null) {
            this.snackBar.open(`${err.error.message}`, 'ok', {
              duration: 11000,
              panelClass: ['MySnackBar', 'MySnackBar--error'],
            });
          } else {
            this.myErrorUtils.showHttpError(err.error);
          }

          return this.myHttpUtils.breakObservableChain();
        }),
      )
      .subscribe((data: ILoginResponse) => {
        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

        this.myGlobals.userToken = data.token;
        this.myGlobals.userInfo = data.contentResponse.user;

        if (Array.isArray(data.contentResponse.pessoa)) {
          this.myGlobals.personInfo = data.contentResponse.pessoa[0];
        } else {
          this.myGlobals.personInfo = data.contentResponse.pessoa;
        }

        if (!data.contentResponse.user.isActivated) {
          this.snackBar.open('Usuário inativo', 'ok', {
            duration: 5000,
            panelClass: ['MySnackBar', 'MySnackBar--warning'],
          });

          this.router.navigate(['/validar-token'], {});

          return;
        }

        const source: ILoginInfoDb = {
          userToken: this.myGlobals.userToken,
          userInfo: this.myGlobals.userInfo,
          personInfo: this.myGlobals.personInfo
        };

        this.localStorage.set('userData', JSON.stringify(source));

        this.router.navigate([this.afterLoginRoute], {});
      });
  }

  submitRecovery() {
    if (!this.recoveryForm.valid) {
      return;
    }

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    this.http.get(`${this.myGlobals.baseUrlApi}/recover/${btoa(this.recoveryForm.get('email').value)}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.myErrorUtils.showHttpError(err.error.contentResponse);

          return this.myHttpUtils.breakObservableChain();
        }),
      )
      .subscribe((data: any) => {
        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

        this.snackBar.open('Foi enviado um email com sua nova senha', 'ok', {
          duration: 5000,
          panelClass: ['MySnackBar', 'MySnackBar--success'],
        });
      });
  }
}
