import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {InfoEnderecoComponent} from './info-endereco.component';
import {PageTitleModule} from '../../../components/page-title/page-title.module';
import {IMaskModule} from 'angular-imask';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [InfoEnderecoComponent],
  imports: [
    CommonModule,
    PageTitleModule,
    IMaskModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule
  ],
  exports: [InfoEnderecoComponent]
})
export class InfoEnderecoModule { }
