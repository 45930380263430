import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import {catchError} from 'rxjs/operators';
import {MyHttpUtils} from '../../../services/utils/HttpUtils';
import {EPageLoaderStatus} from '../../../services/my-globals/definitions';
import {IServerError} from '../../../services/utils/error/definitions';
import {ErrorUtilsService} from '../../../services/utils/error/error-utils.service';

@Component({
  selector: 'app-valida-token',
  templateUrl: './valida-token.component.html',
  styleUrls: ['./valida-token.component.scss']
})
export class ValidaTokenComponent implements OnInit {
  public tokenForm: FormGroup;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private myHttpUtils: MyHttpUtils,
    private myErrorUtils: ErrorUtilsService,
    private myGlobals: MyGlobalsService
  ) {
  }

  public ngOnInit() {
    this.initForm();
  }

  public submitToken() {
    if(!this.tokenForm.valid) {
      this.snackBar.open('Faltou preencher os campos obrigatórios', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    this.http.post(`${this.myGlobals.baseUrlApi}/validateToken`, {
      username: this.myGlobals.userInfo.username,
      token: this.tokenForm.get('token').value
    })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.myErrorUtils.showHttpError(err.error.contentResponse);

          return this.myHttpUtils.breakObservableChain();
        })
      )
      .subscribe((resp: any) => {
        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

        this.router.navigate(['emprestimo/estados-permitidos'], {});
      });
  }

  private initForm() {
    this.tokenForm = this.formBuilder.group({
      token: ['', [Validators.required]]
    });
  }

  public cancel() {
    this.router.navigate(['/novo-cliente'], {});
  }
}
