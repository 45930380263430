import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmPhoto2Component} from './confirm-photo2.component';
import { MatButtonModule } from '@angular/material/button';
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,

    ],
  declarations: [ConfirmPhoto2Component],
  exports: [ConfirmPhoto2Component]
})
export class ConfirmPhoto2Module {
}
