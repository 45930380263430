import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {InfoEmprestimoComponent} from './info-emprestimo.component';
import {PageTitleModule} from '../../../components/page-title/page-title.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {IMaskModule} from 'angular-imask';
// import {SatDatepickerModule, SatNativeDateModule} from 'saturn-datepicker';
import {NgxCurrencyModule} from 'ngx-currency';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
  declarations: [InfoEmprestimoComponent],
    imports: [
        CommonModule,
        PageTitleModule,
        ReactiveFormsModule,
        IMaskModule,
        // SatDatepickerModule,
        // SatNativeDateModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        NgxCurrencyModule,
        MatSelectModule
    ],
  exports: [InfoEmprestimoComponent]
})
export class InfoEmprestimoModule { }
