import {Injectable} from '@angular/core';
import {EPageLoaderStatus} from '../my-globals/definitions';
import {catchError} from 'rxjs/operators';
import {IBankCondeData} from '../../pages/emprestimo/info-banco/definitions';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MyGlobalsService} from '../my-globals/my-globals.service';
import {MyHttpUtils} from './HttpUtils';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {IBankInfo} from '../../pages/emprestimo/definitions';
import {Observable, Subscriber} from 'rxjs';
import {ErrorUtilsService} from './error/error-utils.service';

declare const Moip: {
    BankAccount: {
      validate: (data: any) => {}
    };
  }
;

@Injectable({
  providedIn: 'root'
})
export class BankUtilsService {

  constructor(
    private snackBar: MatSnackBar,
    private myGlobals: MyGlobalsService,
    private myHttpUtils: MyHttpUtils,
    private myErrorUtils: ErrorUtilsService,
    private http: HttpClient
  ) {
  }

  public loadBanks() {
    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    return this.http.get(`${this.myGlobals.baseUrlApi}/api/bancos?page=1&perPage=300`, {
      headers: this.myHttpUtils.configBasicAuthHeader()
    })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.myErrorUtils.showHttpError(err.error);

          return this.myHttpUtils.breakObservableChain();
        })
      );
  }

  public filterBanks(val: string, bankInfoData: Array<IBankCondeData>): IBankCondeData[] {
    if (!bankInfoData) {
      return [];
    }

    return bankInfoData.filter((bank: IBankCondeData) => {
      return bank.nome.toLowerCase().includes(val.toLowerCase());
    });
  }

  /**
   *
   * @param val
   * @param bankInfoData
   * @return Retorna -1 quando não existe o banco ou o código do banco
   */
  public bankNameToNumber(val: string, bankInfoData: Array<IBankCondeData>): number {
    const bank = bankInfoData.filter((data: IBankCondeData) => {
      return data.nome.trim().toLowerCase() === val.trim().toLowerCase();
    });

    return bank.length == 0 ? -1 : bank[0].numero;
  }

  public bankNumberToName(val: number, bankInfoData: Array<IBankCondeData>): string {
    const bank = bankInfoData.filter((data: IBankCondeData) => {
      return data.numero === val;
    });

    return bank.length == 0 ? '' : bank[0].nome;
  }

  public generateBankNameWithNumber(banks: Array<IBankCondeData>) {

    for (let i = 0; i < banks.length; ++i) {
      const nome = banks[i].nome;
      const numero = banks[i].numero + '';
      banks[i].nome = `${numero.padStart(3, '0')} - ${nome}`;
    }

    return banks;
  }

  public validateBankInfo(info: IBankInfo) {
    return new Observable((subs: Subscriber<string>) => {
      Moip.BankAccount.validate({
        bankNumber: info.bancoPagto + '',
        agencyNumber: info.agenciaPagto,
        agencyCheckNumber: info.digitoAgenciaPagto,
        accountNumber: info.contaPagto,
        accountCheckNumber: info.digitoContaPagto,
        valid: function() {
          subs.next('');
        },
        invalid: function(data) {
          let errors = 'Conta bancária inválida: ';
          for (const i in data.errors) {
            errors += data.errors[i].description + ' ; ';
          }
          subs.next(errors);
        }
      });
    });

  }
}
