<div class="InfoEndereco">
  <app-page-title>Onde você mora?</app-page-title>

  <form [formGroup]="mainForm" (submit)="nextPage();" novalidate>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        [imask]="cepMask"
        type="tel"
        placeholder="CEP Residencial"
        name="cep"
        formControlName="cep"
        (keyup)="onClickSearchCep()"
      >
    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        placeholder="Endereço"
        name="address1"
        formControlName="endereco"
      >
      <button type="button" mat-button *ngIf="showClearFieldBtn('endereco')" matSuffix mat-icon-button aria-label="Limpar" (click)="onClickClearField('endereco');">
        <mat-icon class="Button-clearInput">
          <i class="icofont-close"></i>
        </mat-icon>
      </button>

      <mat-error *ngIf="mainForm.get('endereco').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        placeholder="Complemento"
        name="address2"
        formControlName="complemento"
      >
      <button type="button" mat-button *ngIf="showClearFieldBtn('complemento')" matSuffix mat-icon-button aria-label="Limpar" (click)="onClickClearField('complemento');">
        <mat-icon class="Button-clearInput">
          <i class="icofont-close"></i>
        </mat-icon>
      </button>

      <mat-error *ngIf="mainForm.get('complemento').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        placeholder="Número"
        name="number"
        formControlName="numero"
      >
      <button type="button" mat-button *ngIf="showClearFieldBtn('numero')" matSuffix mat-icon-button aria-label="Limpar" (click)="onClickClearField('number');">
        <mat-icon class="Button-clearInput">
          <i class="icofont-close"></i>
        </mat-icon>
      </button>

      <mat-error *ngIf="mainForm.get('numero').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        placeholder="Bairro"
        name="neighborhood"
        formControlName="bairro"
      >
      <button type="button" mat-button *ngIf="showClearFieldBtn('bairro')" matSuffix mat-icon-button aria-label="Limpar" (click)="onClickClearField('bairro');">
        <mat-icon class="Button-clearInput">
          <i class="icofont-close"></i>
        </mat-icon>
      </button>

      <mat-error *ngIf="mainForm.get('bairro').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <input
        class="bigFont"
        matInput
        placeholder="Cidade"
        name="city"
        formControlName="cidade"
      >
      <button type="button" mat-button *ngIf="showClearFieldBtn('cidade')" matSuffix mat-icon-button aria-label="Limpar" (click)="onClickClearField('cidade');">
        <mat-icon class="Button-clearInput">
          <i class="icofont-close"></i>
        </mat-icon>
      </button>

      <mat-error *ngIf="mainForm.get('cidade').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <mat-select class="bigFont" placeholder="Estado" formControlName="estado">

        <mat-option *ngFor="let state of brazilStates" [value]="state.abbr">{{state.state}}</mat-option>

      </mat-select>

      <mat-error *ngIf="mainForm.get('estado').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <div class="flex-end">
      <button mat-raised-button color="accent">Continuar</button>
    </div>

  </form>

</div>
