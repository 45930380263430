import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmprestimoComponent } from '../emprestimo/emprestimo.component';
import {RouterModule} from '@angular/router';
import { InfoParentesComponent } from './info-parentes/info-parentes.component';
import { InfoContatoComponent } from './info-contato/info-contato.component';
import { InfoFotoDocsComponent } from './info-foto-docs/info-foto-docs.component';
import { InfoBancoComponent } from './info-banco/info-banco.component';
import { FinalStepComponent } from './final-step/final-step.component';

@NgModule({
  declarations: [EmprestimoComponent],
    imports: [
        CommonModule,
        RouterModule
    ]
})
export class EmprestimoModule { }
