import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  BrazilStatesList,
  CEPMask,
  CivilState,
  CpfMask,
  EmailMask,
  EPageLoaderStatus,
  RgMask,
  TelMask,
  WorkState
} from '../../../services/my-globals/definitions';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MyHttpUtils} from '../../../services/utils/HttpUtils';
import {MyGlobalsService} from '../../../services/my-globals/my-globals.service';
import {DateUtilsService} from '../../../services/utils/date-utils.service';
import {cellphoneValidator} from '../../../form-validators/cellphone.validator.directive';
import {catchError, concatMap, finalize, map, startWith} from 'rxjs/operators';
import {StringUtilsService} from '../../../services/utils/string-utils.service';
import {IApiCep, IPersonInfo, ISystemUser} from './definitions';
import {IBankInfo, IPersonInfo as IPersonInfo2} from '../../emprestimo/definitions';
import {telephoneValidator} from '../../../form-validators/telephone.validator.directive';
import {HowReceivePayment, IBankCondeData, IHttpResponseBankList} from '../../emprestimo/info-banco/definitions';
import {EMPTY, Observable} from 'rxjs';
import {BankUtilsService} from '../../../services/utils/bank-utils.service';
import {ErrorUtilsService} from '../../../services/utils/error/error-utils.service';
import {Router} from '@angular/router';
import {dateValidator} from '../../../form-validators/date.validator.directive';
import {ILoginInfoDb} from '../../login/definitions';
import {LocalStorageService} from "../../../services/local-storage.service";

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  public mainForm: FormGroup;
  public accountForm: FormGroup;
  public preProposalForm: FormGroup;

  public cepMask = CEPMask;
  public brazilStates = BrazilStatesList;
  public telMask = TelMask;
  public cpfMask = CpfMask;
  public workState = WorkState;
  public rgMask = RgMask;
  public emailMask = EmailMask;

  public birthDateMask = {};
  public workAdmissionMask = {};
  public requestingCep = false;
  public isPreProposal = false;

  public howReceivePayment = HowReceivePayment;
  public civilState = CivilState;

  public bankInfoData: Array<IBankCondeData>;
  public bankInfoDataFiltered: Observable<Array<IBankCondeData>>;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private myHttpUtils: MyHttpUtils,
    private myGlobals: MyGlobalsService,
    private dateUtils: DateUtilsService,
    private stringUtils: StringUtilsService,
    private bankUtils: BankUtilsService,
    private myErrorUtils: ErrorUtilsService,
    private myStringUtils: StringUtilsService,
    private localStorage: LocalStorageService
  ) {
    const storageData:ILoginInfoDb = this.localStorage.get('userData');

    this.myGlobals.userToken = storageData.userToken;
    this.myGlobals.userInfo = storageData.userInfo;
    this.myGlobals.personInfo = storageData.personInfo;
  }

  ngOnInit() {
    this.initForm();
  }

  onClickClearField(str: string) {
    const val = {};
    val[str] = '';

    this.mainForm.patchValue(val);
  }

  savePerson() {
    if (!this.mainForm.valid) {
      this.snackBar.open('Faltou preencher os campos obrigatórios', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    const personData: IPersonInfo2 = this.mainForm.getRawValue();
    personData.myUserId = this.myGlobals.userInfo.id;

    personData.cep = this.myStringUtils.removeCepMask(personData.cep);
    personData.empresaCep = this.myStringUtils.removeCepMask(personData.empresaCep);

    personData.rg = this.myStringUtils.removeRgMask(personData.rg);

    personData.dataNascimento = this.myStringUtils.convertDateBrToUs(personData.dataNascimento);
    personData.dataNascimento = this.myStringUtils.removeDateMask(personData.dataNascimento);

    personData.dataAdmissaoTrabalho = this.myStringUtils.convertDateBrToUs(personData.dataAdmissaoTrabalho);
    personData.dataAdmissaoTrabalho = this.myStringUtils.removeDateMask(personData.dataAdmissaoTrabalho);

    personData.telefoneCelular = this.myStringUtils.removeTelMask(personData.telefoneCelular);
    personData.telefoneResidencial = this.myStringUtils.removeTelMask(personData.telefoneResidencial);
    personData.referenciaTelefone = this.myStringUtils.removeTelMask(personData.referenciaTelefone);
    personData.empresaTelefone = this.myStringUtils.removeTelMask(personData.empresaTelefone);

    personData.cpf = this.myStringUtils.removeCpfMask(personData.cpf);
    personData.bancoPagto = this.bankUtils.bankNameToNumber(personData.bancoPagtoName, this.bankInfoData);

    const bankInfo: IBankInfo = {
      agenciaPagto: personData.agenciaPagto,
      bancoPagto: personData.bancoPagto,
      bancoPagtoName: personData.bancoPagtoName,
      contaPagto: personData.contaPagto,
      digitoAgenciaPagto: personData.digitoAgenciaPagto,
      digitoContaPagto: personData.digitoContaPagto,
      tipoContaPagto: personData.tipoContaPagto
    };

    this.bankUtils.validateBankInfo(bankInfo)
      .pipe(
        concatMap(() => {
          return this.http.put(`${this.myGlobals.baseUrlApi}/api/pessoas/${this.myGlobals.personInfo.id}`, personData, {
            headers: this.myHttpUtils.configBasicAuthHeader()
          });
        }),

        catchError((err: HttpErrorResponse) => {
          if (err.error.contentResponse.errorsFields) {
            this.myErrorUtils.showHttpError(err.error.contentResponse.fields[0]);
          } else {
            this.myErrorUtils.showHttpError(err.error.contentResponse);
          }

          return this.myHttpUtils.breakObservableChain();
        })
      )
      .subscribe((data: any) => {
        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

        if (!data.success) {
          this.snackBar.open(data.contentResponse.mensagem, 'ok', {
            duration: 5000,
            panelClass: ['MySnackBar', 'MySnackBar--success'],
          });

          return;
        }

        this.myGlobals.personInfo = this.personInfo2ToPersonInfo(personData);

        const source: ILoginInfoDb = {
          userToken: this.myGlobals.userToken,
          userInfo: this.myGlobals.userInfo,
          personInfo: this.myGlobals.personInfo
        };

        source.personInfo.dataNascimento.date =
          this.myStringUtils.convertDatePadraoToUs(source.personInfo.dataNascimento.date);

        source.personInfo.dataAdmissaoTrabalho.date =
          this.myStringUtils.convertDatePadraoToUs(source.personInfo.dataAdmissaoTrabalho.date);

        this.localStorage.set('userData', JSON.stringify(source));

        this.snackBar.open('Salvo com sucesso.', 'ok', {
          duration: 5000,
          panelClass: ['MySnackBar', 'MySnackBar--success'],
        });
      });

  }

  private initForm() {
    this.birthDateMask = this.dateUtils.configBirthDateMask();
    this.workAdmissionMask = this.dateUtils.configAdmissionDateMask();
    const person = this.myGlobals.personInfo;

    this.isPreProposal = !person;

    this.initFormFields();
  }

  private initFormFields() {
    this.accountForm = this.formBuilder.group({
      username: [{
        value: this.myGlobals.userInfo.username,
        disabled: true
      }, [
        Validators.required
      ]],
      oldPass: ['', [
        Validators.required
      ]],
      newPass: ['', [
        Validators.required
      ]],
      newPassAgain: ['', [
        Validators.required
      ]]
    });

    this.mainForm = this.formBuilder.group({
      cep: ['', [Validators.required]],
      endereco: ['', [Validators.required]],
      numero: ['', [Validators.required]],
      complemento: ['', [Validators.required]],
      bairro: ['', [Validators.required]],
      cidade: ['', [Validators.required]],
      estado: ['', [Validators.required]],

      cpf: [{
        value: '',
        disabled: true
      }, [
        Validators.required
      ]],
      nome: ['', [Validators.required]],
      dataNascimento: ['', [
        Validators.required,
        dateValidator
      ]],
      sexo: ['', [Validators.required]],
      rg: ['', [Validators.required]],
      rgOrgaoEmissor: ['', [Validators.required]],
      rgUf: ['', [Validators.required]],
      nomeMae: ['', [Validators.required]],
      nomePai: ['', [Validators.required]],

      referenciaNome: ['', [Validators.required]],
      referenciaTelefone: ['', [Validators.required]],

      telefoneCelular: ['', [cellphoneValidator, Validators.required]],
      telefoneResidencial: ['', [telephoneValidator, Validators.required]],
      email: [{
        value: '',
        disabled: true
      }, [Validators.email, Validators.required]],

      estadoCivil: ['', [Validators.required]],
      nacionalidade: ['', []],
      ufNaturalidade: [{
        value: '',
        disabled: true
      }, []],

      cargoFuncaoTrabalho: ['', [Validators.required]],
      renda: ['', [Validators.required]],
      dataAdmissaoTrabalho: ['', [Validators.required, dateValidator]],
      situacaoTrabalho: ['', [Validators.required]],
      empresaNome: ['', [Validators.required]],
      empresaTelefone: ['', [Validators.required]],
      empresaCep: ['', [Validators.required]],
      empresaEndereco: ['', [Validators.required]],
      empresaEnderecoNumero: ['', [Validators.required]],
      empresaBairro: ['', [Validators.required]],
      empresaCidade: ['', [Validators.required]],
      empresaUf: ['', [Validators.required]],

      tipoContaPagto: ['', [Validators.required]],
      bancoPagtoName: ['', [Validators.required]],
      bancoPagto: ['', [Validators.required]],
      agenciaPagto: ['', [Validators.required]],
      digitoAgenciaPagto: ['', []],
      contaPagto: ['', [Validators.required]],
      digitoContaPagto: ['', []],
    });

    this.preProposalForm = this.formBuilder.group({
      fullName2: [this.myGlobals.userInfo.fullName, [
        Validators.required
      ]],
      bithDate2: [this.stringUtils.convertDateUsToBr(this.myGlobals.userInfo.bithDate.date.substr(0, 10)), [
        Validators.required,
        dateValidator
      ]],
      phone2: [this.myGlobals.userInfo.phone, [
        Validators.required
      ]],
      cpf2: [{
        value: this.myGlobals.userInfo.cpf,
        disabled: true
      }, [
        Validators.required
      ]]
    });

    setTimeout(() => {
      this.loadFormFields(this.myGlobals.personInfo);
    }, 0);
  }

  private loadFormFields(data: IPersonInfo) {
    if (!data) {
      return;
    }

    this.bankInfoDataFiltered = this.mainForm.get('bancoPagtoName').valueChanges
      .pipe(
        startWith(''),
        map(value => this.bankUtils.filterBanks(value, this.bankInfoData))
      );

    this.bankUtils.loadBanks()
      .subscribe((res: IHttpResponseBankList) => {
        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

        this.bankInfoData = this.bankUtils.generateBankNameWithNumber(res.contentResponse);

        const bankSelected = this.bankUtils.bankNumberToName(this.myGlobals.personInfo.bancoPagto, this.bankInfoData);
        this.mainForm.patchValue({bancoPagtoName: bankSelected});
      });

    let dataNascimento = data.dataNascimento.date.split(' ')[0];
    dataNascimento = this.stringUtils.convertDateUsToBr(dataNascimento);

    let dataAdmissaoTrabalho = data.dataAdmissaoTrabalho.date.split(' ')[0];
    dataAdmissaoTrabalho = this.stringUtils.convertDateUsToBr(dataAdmissaoTrabalho);

    this.mainForm.patchValue({
      cep: data.cep,
      endereco: data.endereco,
      numero: data.numero,
      complemento: data.complemento,
      bairro: data.bairro,
      cidade: data.cidade,
      estado: data.estado,

      cpf: data.cpf,
      nome: data.nome,
      dataNascimento: dataNascimento,
      sexo: data.sexo,
      rg: data.rg,
      rgOrgaoEmissor: data.rgOrgaoEmissor,
      rgUf: data.rgUf,
      nomeMae: data.nomeMae,
      nomePai: data.nomePai,
      referenciaNome: data.referenciaNome,
      referenciaTelefone: data.referenciaTelefone,

      telefoneCelular: data.telefoneCelular,
      telefoneResidencial: data.telefoneResidencial,
      email: data.email,

      estadoCivil: data.estadoCivil + '',
      nacionalidade: '',
      ufNaturalidade: '',

      cargoFuncaoTrabalho: data.cargoFuncaoTrabalho,
      renda: data.renda,
      dataAdmissaoTrabalho: dataAdmissaoTrabalho,
      situacaoTrabalho: data.situacaoTrabalho + '',
      empresaNome: data.empresaNome,
      empresaTelefone: data.empresaTelefone,
      empresaCep: data.empresaCep,
      empresaEndereco: data.empresaEndereco,
      empresaEnderecoNumero: data.empresaEnderecoNumero,
      empresaBairro: data.empresaBairro,
      empresaCidade: data.empresaCidade,
      empresaUf: data.empresaUf,

      tipoContaPagto: data.tipoContaPagto,
      bancoPagtoName: '',
      bancoPagto: data.bancoPagto,
      agenciaPagto: data.agenciaPagto,
      digitoAgenciaPagto: data.digitoAgenciaPagto,
      contaPagto: data.contaPagto,
      digitoContaPagto: data.digitoContaPagto,
    });

  }

  deleteAccount() {
    this.http.delete(`${this.myGlobals.baseUrlApi}/api/users/${this.myGlobals.userInfo.id}`, {
      headers: this.myHttpUtils.configBasicAuthHeader()
    })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.myErrorUtils.showHttpError(err.error.contentResponse);

          return this.myHttpUtils.breakObservableChain();
        })
      )
      .subscribe((data: any) => {
        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

        this.snackBar.open('Conta deletada com sucesso', 'ok', {
          duration: 5000,
          panelClass: ['MySnackBar', 'MySnackBar--success'],
        });

        this.router.navigate(['/login'], {});
      });
  }

  onClickSearchCepPersonal() {
    let cep: string = this.mainForm.get('cep').value;

    if (cep.length !== 9 || this.requestingCep) {
      return;
    }

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    this.requestingCep = true;

    cep = this.stringUtils.removeCepMask(cep);
    this.http.get(this.myGlobals.getCepUrl(cep))
      .subscribe((address: IApiCep) => {

        this.mainForm.get('numero').setValue('');
        this.mainForm.get('endereco').setValue(address.logradouro);
        this.mainForm.get('complemento').setValue(address.complemento);
        this.mainForm.get('bairro').setValue(address.bairro);
        this.mainForm.get('cidade').setValue(address.localidade);
        this.mainForm.get('estado').setValue(address.uf);

        this.requestingCep = false;

        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);
      });
  }

  onClickSearchCepWork() {
    let cep: string = this.mainForm.get('empresaCep').value;

    if (cep.length !== 9 || this.requestingCep) {
      return;
    }

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    this.requestingCep = true;

    cep = this.myStringUtils.removeCepMask(cep);
    this.http.get(this.myGlobals.getCepUrl(cep))
      .subscribe((address: IApiCep) => {

        this.mainForm.get('empresaEnderecoNumero').setValue('');
        this.mainForm.get('empresaEndereco').setValue(address.logradouro);
        this.mainForm.get('empresaBairro').setValue(address.bairro);
        this.mainForm.get('empresaCidade').setValue(address.localidade);
        this.mainForm.get('empresaUf').setValue(address.uf);

        this.requestingCep = false;

        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);
      });
  }

  changePassword() {

    if (!this.accountForm.valid) {
      this.snackBar.open('Faltou preencher os campos obrigatórios', 'ok', {
        duration: 5000,
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return;
    }

    const systemUser = {
      myUserId: this.myGlobals.userInfo.id,
      oldPassword: this.accountForm.get('oldPass').value,
      newPassword: this.accountForm.get('newPass').value
    };

    this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.visible);

    this.http.post(`${this.myGlobals.baseUrlApi}/api/users/changePassword`, systemUser, {
      headers: this.myHttpUtils.configBasicAuthHeader()
    })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.myErrorUtils.showHttpError(err.error.contentResponse);

          return this.myHttpUtils.breakObservableChain();
        })
      )
      .subscribe((data: any) => {
        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);

        this.snackBar.open('Alteração salva com sucesso.', 'ok', {
          duration: 5000,
          panelClass: ['MySnackBar', 'MySnackBar--success'],
        });
      });
  }

  preProposalSaveInfo() {

    const personData: {
      fullName2: string;
      bithDate2: string;
      phone2: string;
      cpf2: string;
    } = this.preProposalForm.getRawValue();

    let bithDate = this.myStringUtils.convertDateBrToUs(personData.bithDate2.replace(/\//g, '.'));
    bithDate = this.myStringUtils.removeDateMask(bithDate);

    const systemUser: ISystemUser = {
      bithDate: bithDate,
      fullName: personData.fullName2,
      phone: this.myStringUtils.removeTelMask(personData.phone2)
    };

    this.http.put(`${this.myGlobals.baseUrlApi}/api/users/${this.myGlobals.userInfo.id}`, systemUser, {
      headers: this.myHttpUtils.configBasicAuthHeader()
    })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.myErrorUtils.showHttpError(err.error.contentResponse);

          return this.myHttpUtils.breakObservableChain();
        })
      )
      .subscribe((data: any) => {
        this.myGlobals.setGlobalBarLoaderStatus(EPageLoaderStatus.hidden);
      });
  }

  personInfo2ToPersonInfo(personData: IPersonInfo2): IPersonInfo {
    return {
      _links: this.myGlobals.personInfo._links,
      agenciaPagto: personData.agenciaPagto,
      bairro: personData.bairro,
      bancoPagto: personData.bancoPagto,
      cargoFuncaoTrabalho: personData.cargoFuncaoTrabalho,
      cep: personData.cep,
      cidade: personData.cidade,
      complemento: personData.complemento,
      contaPagto: personData.contaPagto,
      cpf: personData.cpf,
      dataAdmissaoTrabalho: {date: `${personData.dataAdmissaoTrabalho} 00:00:00.000000`, timezone: 'UTC', timezone_type: 3},
      dataCadastro: this.myGlobals.personInfo.dataCadastro,
      dataNascimento: {date: `${personData.dataNascimento} 00:00:00.000000`, timezone: 'UTC', timezone_type: 3},
      digitoAgenciaPagto: personData.digitoAgenciaPagto,
      digitoContaPagto: personData.digitoContaPagto,
      email: personData.email,
      empresaBairro: personData.empresaBairro,
      empresaCep: personData.empresaCep,
      empresaCidade: personData.empresaCidade,
      empresaEndereco: personData.empresaEndereco,
      empresaEnderecoNumero: personData.empresaEnderecoNumero,
      empresaNome: personData.empresaNome,
      empresaTelefone: personData.empresaTelefone,
      empresaUf: personData.empresaUf,
      endereco: personData.endereco,
      estado: personData.estado,
      estadoCivil: personData.estadoCivil,
      id: this.myGlobals.personInfo.id,
      legenda: this.myGlobals.personInfo.legenda,
      myUserId: this.myGlobals.personInfo.myUserId,
      nome: personData.nome,
      nomeMae: personData.nomeMae,
      nomePai: personData.nomePai,
      numero: personData.numero,
      referenciaNome: personData.referenciaNome,
      referenciaTelefone: personData.referenciaTelefone,
      renda: personData.renda + '',
      rg: personData.rg,
      rgOrgaoEmissor: personData.rgOrgaoEmissor,
      rgUf: personData.rgUf,
      sexo: personData.sexo,
      situacaoTrabalho: personData.situacaoTrabalho,
      telefoneCelular: personData.telefoneCelular,
      telefoneResidencial: personData.telefoneResidencial,
      tipoContaPagto: personData.tipoContaPagto,
      ufNaturalidade: personData.ufNaturalidade,
    };
  }

}
