import { Component, OnInit } from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style([{
          opacity: 0,
          overflow: 'hidden',
          transform: 'scale(0)'
        }]),
        animate('300ms ease-in-out', style([{opacity: 1, transform: 'scale(1)'}]))
      ]),
      transition(':leave', [
        animate('300ms ease-in-out', style([{opacity: 0, transform: 'scale(0)'}]))
      ])
    ])
  ]
})
export class PageLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
