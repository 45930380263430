import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {MyGlobalsService} from '../services/my-globals/my-globals.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UsernameGuard implements CanActivate {

  constructor(
    private router: Router,
    private myGlobals: MyGlobalsService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if(!this.myGlobals.userInfo || !this.myGlobals.userInfo.username) {
      this.router.navigate(['/login'], {});

      return false;
    }

    return true;
  }

}
