import { Injectable } from '@angular/core';
import {EMainMenuStatus, EPageLoaderStatus, IUserInfo} from './definitions';
import {BehaviorSubject} from 'rxjs';
import {useTerms} from './user-terms';
import {IPersonInfo} from '../../pages/cliente/perfil/definitions';

@Injectable({
  providedIn: 'root'
})
export class MyGlobalsService {

  public static readonly maxLoan = 5000;

  private _isMobile = false;

  private _userInfo: IUserInfo = null;
  private _personInfo: IPersonInfo = null;

  public readonly appVersion = '1.0.17.0'; // major.minor.bug_fix.build

  private _userToken = '';

  private readonly _cepUrl = 'https://viacep.com.br/ws/!cep!/json/';
  private readonly _baseUrlApi = 'https://www2.ucredit.com.br/emprestimoApi/public/index.php/';
  private readonly _baseUrlApiUcred = 'https://api.ucred.net.br';
   // private readonly _baseUrlApi = '/api';
  private readonly _useTerms = useTerms;

  private readonly _localDatabaseName = 'UcreditApp';
  private readonly _afterLoginRoute = '/emprestimo/estados-permitidos';

  public readonly pageLoaderInitialStatus: EPageLoaderStatus = EPageLoaderStatus.hidden;
  private pageLoaderStatusObservable: BehaviorSubject<EPageLoaderStatus> = new BehaviorSubject<EPageLoaderStatus>(this.pageLoaderInitialStatus);
  private pageLoaderStatus: EPageLoaderStatus = this.pageLoaderInitialStatus;

  public readonly mainMenuInitialStatus: EMainMenuStatus = EMainMenuStatus.hidden;
  private mainMenuStatusObservable: BehaviorSubject<EMainMenuStatus> = new BehaviorSubject<EMainMenuStatus>(this.mainMenuInitialStatus);
  private mainMenuStatus: EMainMenuStatus = this.mainMenuInitialStatus;

  constructor() { }

  get isMobile(): boolean {
    return this._isMobile;
  }

  set isMobile(value: boolean) {
    this._isMobile = value;
  }

  getCepUrl(cep: string): string {
    return this._cepUrl.replace(/!cep!/g, cep);
  }

  get baseUrlApi(): string {
    return this._baseUrlApi;
  }

  get baseUrlApiUcred(): string {
    return this._baseUrlApiUcred;
  }

  get useTerms(): string {
    return this._useTerms;
  }

  getGlobalBarLoaderStatus() {
    return this.pageLoaderStatusObservable;
  }

  setGlobalBarLoaderStatus(value: EPageLoaderStatus) {
    this.pageLoaderStatus = value;
    this.pageLoaderStatusObservable.next(value);
  }

  getGlobalMainMenuStatus() {
    return this.mainMenuStatusObservable;
  }

  setGlobalMainMenuStatus(value: EMainMenuStatus) {
    this.mainMenuStatus = value;
    this.mainMenuStatusObservable.next(value);
  }

  get userInfo(): IUserInfo {
    return this._userInfo;
  }

  set userInfo(value: IUserInfo) {
    this._userInfo = value;
  }

  get userToken(): string {
    return this._userToken;
  }

  set userToken(value: string) {
    this._userToken = value;
  }

  get personInfo(): IPersonInfo {
    return this._personInfo;
  }

  set personInfo(value: IPersonInfo) {
    this._personInfo = value;
  }

  get localDatabaseName(): string {
    return this._localDatabaseName;
  }

  get afterLoginRoute(): string {
    return this._afterLoginRoute;
  }
}
