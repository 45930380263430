<div class="TakePhotoDialog" #dialogMainDiv>
  <canvas #cameraProcessing style="position: absolute; top: -5000px; left: -5000px; opacity: 0.01;"></canvas>

  <button mat-icon-button class="TakePhotoDialog-button--close" (click)="closeModal()">
    <i class="icofont-close"></i>
  </button>

  <button  *ngIf="sliderPosition === 0" mat-icon-button class="TakePhotoDialog-button--changeCamera" (click)="changeCamera()">
    <i class="icofont-refresh"></i>
  </button>

  <div class="TakePhotoDialog-slider">

    <div class="TakePhotoDialog-slide" *ngIf="sliderPosition === 0">

      <div [ngClass]="{'TakePhotoDialog-cameraDisplay--isFaceMatch': modalParams.isFaceMatch}" class="TakePhotoDialog-cameraDisplay">
        <div class="TakePhotoDialog-video">
          <video #cameraVideo playsinline autoplay class="TakePhotoDialog-videoElem"></video>
          <img *ngIf="modalParams.isPersonPhoto && !modalParams.isFaceMatch" class="TakePhotoDialog-imageOverlay" src="../../../../assets/images/mask-face.png" alt="">
          <img *ngIf="!modalParams.isPersonPhoto && !modalParams.isFaceMatch" class="TakePhotoDialog-imageOverlay" src="../../../../assets/images/mask-doc.png" alt="">
          <img *ngIf="modalParams.isFaceMatch" class="TakePhotoDialog-imageOverlay" src="../../../../assets/images/faceMatchMask2.png" alt="">
        </div>

        <button mat-fab (click)="takePhoto()">
          <i class="icofont-camera"></i>
        </button>

      </div>
    </div>

    <div class="TakePhotoDialog-slide" *ngIf="sliderPosition === 1">
      <app-confirm-photo2
        [photo]="cameraImg"
        (cancel)="cancelPhotoSend();"
        (send)="onSendPhoto()"
        [photoError]="photoError"
        [isLandscape]="isLandscape"
      ></app-confirm-photo2>
    </div>

  </div>

</div>
