import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ListaPedidosComponent} from './lista-pedidos.component';
import {MatCardModule} from '@angular/material/card';
import {PageTitleModule} from '../../../components/page-title/page-title.module';
import { MatRippleModule } from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    ListaPedidosComponent,

  ],
    imports: [
        CommonModule,
        MatCardModule,
        PageTitleModule,
        MatRippleModule,
        MatButtonModule
    ],
  exports: [
    ListaPedidosComponent,
  ]
})
export class ListaPedidosModule { }
