<div class="InfoEmprestimo">
  <app-page-title>De quanto você precisa?</app-page-title>

  <form [formGroup]="mainForm" (submit)="nextPage();" novalidate>

    <mat-form-field class="fullWidth InfoEmprestimo-input--loan">
      <input
        matInput
        placeholder="De quanto precisa?"
        formControlName="financiado"
        type="tel"
        currencyMask
        [options]="{nullable:false}"
        (keyup)="onLoanChange()"
      >

      <mat-error *ngIf="mainForm.get('financiado').hasError('max')">
        Valor máximo permitido é R$ {{maxLoan}},00
      </mat-error>

      <mat-error *ngIf="mainForm.get('financiado').hasError('required')">
        Campo obrigatório
      </mat-error>

      <mat-hint align="start"><strong>Máximo de R$ {{maxLoan}},00</strong></mat-hint>

    </mat-form-field>

    <mat-form-field class="datePicker fullWidth" (click)="picker.open()">
      <input
        class="bigFont"
        matInput
        readonly
        [matDatepicker]="picker"
        [min]="minDate"
        [max]="maxDate"
        (dateChange)="onDateChange();"
        (keydown)="cancelKeypress()"
        formControlName="vencimento_inicial"
        placeholder="Primeiro Vencimento"
      >
      <mat-datepicker-toggle class="Datepicker-icon" matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker color="primary" disabled="false"></mat-datepicker>

      <mat-error *ngIf="mainForm.get('vencimento_inicial').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="fullWidth">
      <mat-select
        class="bigFont"
        placeholder="Em Quantas Vezes?"
        formControlName="prazo"
      >
        <mat-option *ngFor="let item of installments" [value]="item">
          {{item.prazo}} x R$ {{prettifyValorParcela(item.valorParcela)}}
        </mat-option>

      </mat-select>

      <mat-error *ngIf="mainForm.get('prazo').hasError('required')">
        Campo obrigatório
      </mat-error>

    </mat-form-field>

    <div class="flex-end">
      <button mat-raised-button color="accent" [disabled]="blockContinueButton">Continuar</button>
    </div>

  </form>

</div>
