export const HowReceivePayment = [
  {
    text: 'Conta Corrente',
    value: 'ContaCorrente'
  },
  {
    text: 'Poupança',
    value: 'Poupanca'
  },
];

export interface IHttpResponseBankList {
  success: boolean;
  contentResponse: Array<IBankCondeData>;
}

export interface IBankCondeData {
  numero: number,
  nome: string
}
